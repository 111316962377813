import React from "react";
import {WithStyles, withStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {Subscription} from "rxjs";
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {withSnackbar, WithSnackbarProps} from "notistack";

import {eventsQuery, eventsService, EventTemplate} from "../../../store/events";

import {DefaultButton} from "../../../components/Buttons";
import EventCard from "../../../components/events/EventCard";
import AddEvent from "../../../components/events/AddEvent";

import style from './styles/events';

interface EventsProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {}

interface EventsState {
    events: EventTemplate[];
}

class Events extends React.Component<EventsProps, EventsState> {
    private _events?: Subscription = undefined;
    private _getEvents?: Subscription = undefined;

    constructor(props: EventsProps) {
        super(props);

        this.state = {
            events: [],
        };
    }

    componentDidMount() {
        const { enqueueSnackbar, t } = this.props;

        this._getEvents = eventsService.getAllEvents().subscribe(
            (events) => { this.setState({ events }); },
            (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
        );
        this._events = eventsQuery.sortedEvents$.subscribe((events) => {
            this.setState({ events });
        });
    }

    componentWillUnmount() {
        if (this._events) this._events.unsubscribe();
        if (this._getEvents) this._getEvents.unsubscribe();
    }

    reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
        const result = Array.from(list);

        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    onDragEnd = (result: DropResult) => {
        const { enqueueSnackbar, t } = this.props;

        const { events } = this.state;

        if (!result.destination) {
            return;
        }

        const reorderedEvents = this.reorder(
          events,
          result.source.index,
          result.destination.index
        );

        this.setState({ events: reorderedEvents });
        eventsService.updateEventsPositions(result.draggableId, result.destination.index).subscribe(
            () => {},
            (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
        );
    }

    render() {
        const { classes, t } = this.props;

        const { events } = this.state;

        const firstEvent = events.length ? events[0] : undefined;

        const editableEvents = events.slice(1, events.length - 1);

        const lastEvent = events.length > 1 ? events[events.length - 1] : undefined;

        return (
            <div className={classes.gridContent}>
                <div className={classes.buttonsContainer}>
                    <DefaultButton
                        title={t("events.applyToSites")}
                        icon="/images/events/icon_duplicate.svg"
                        onClick={() => {}} />
                </div>
                <div className={classes.listContainer}>
                    {firstEvent && (
                        <div className={classes.eventsContainer}>
                            <EventCard canBeDeleted={false} event={firstEvent} />
                        </div>
                    )}
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className={classes.eventsContainer}>
                                    {editableEvents.map((event, index) => (
                                        <Draggable key={event.id} draggableId={event.id} index={index + 1}>
                                            {(provided, snapshot) => (
                                                <EventCard
                                                    canBeDeleted={true}
                                                    event={event}
                                                    provided={provided}
                                                    snapshot={snapshot} />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {lastEvent && (
                      <div className={classes.eventsContainer}>
                          <EventCard canBeDeleted={false} event={lastEvent} />
                      </div>
                    )}
                    <div className={classes.addEventButton}>
                        <AddEvent />
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(style)(
    withTranslation()(withSnackbar(Events))
);
