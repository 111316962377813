import {AxiosResponse} from "axios";

export const downloadCSV = (res: AxiosResponse) => {
	const file = res.data;
	const fileUrl = URL.createObjectURL(file);

	const fileName = res.headers['content-disposition'].split('filename=')[1].split(';')[0];

	const a = document.createElement('a');
	a.href = fileUrl;
	a.download = fileName;
	a.click();
}
