import { createStyles } from "@material-ui/core";

const events = () => createStyles({
    gridContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '40px 0 0',
        maxWidth: '100vw',
    },
    buttonsContainer: {
        display: 'flex',
        padding: '0 60px',
    },
    listContainer: {
        display: 'flex',
        flex: 1,
        overflowX: 'auto',
        paddingLeft: 50,
    },
    eventsContainer: {
        alignSelf: 'flex-start',
        display: 'flex',
        marginTop: 21,
        padding: '5px 0 5px',
    },
    addEventButton: {
        marginTop: 21,
        padding: '5px 60px 5px 10px'
    },
});

export default events;
