import React, {createRef, useEffect, useState} from "react";
import {
    Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography,
    WithStyles,
    withStyles
} from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";
import {withSnackbar, WithSnackbarProps} from "notistack";
import {forkJoin} from "rxjs";
import {ChevronLeft, ChevronRight, Close} from "@material-ui/icons";

import {ContainerDetailsParams, LightContainer, siteService} from "../../../store/site";
import {Event} from "../../../store/events";

import ContainerEvents from "../../../components/containerDetails/ContainerEvents";

import style from "./styles/containerDetails";

interface ContainerDetailsProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    containers: ContainerDetailsParams[];
    handleClose: () => void;
}

const ContainerDetails = (props: ContainerDetailsProps) => {
    const { classes, containers, enqueueSnackbar, handleClose, t } = props;

    const dialog = createRef();

    const [containerIdIndex, setContainerIdIndex] = useState(0);

    const [details, setDetails] = useState<LightContainer | undefined>();
    const [events, setEvents] = useState<Event[]>([]);

    useEffect(() => {
        forkJoin([
            siteService.fetchContainerDetails(containers[containerIdIndex]),
            siteService.fetchContainerEvents(containers[containerIdIndex]),
        ]).subscribe(
            ([d, e]) => {
                setDetails(d);
                setEvents(e);
            },
            (err) => { enqueueSnackbar(t(err.key), { variant: "error" }); },
        );
    }, [containerIdIndex, containers, enqueueSnackbar, t]);

    return (
        <Dialog ref={dialog} open={true} fullWidth maxWidth="md" onClose={handleClose} scroll="body">
            <DialogTitle className={classes.title} disableTypography>
                {containers.length > 1 && (
                    <IconButton onClick={() => {
                        if (containerIdIndex === 0) {
                            setContainerIdIndex(containers.length - 1);
                        } else {
                            setContainerIdIndex(containerIdIndex - 1);
                        }
                    }}>
                        <ChevronLeft />
                    </IconButton>
                )}
                {details && t("containerDetails.containerDetailsTitle", { serialNumber: details.serialNumber })}
                {containers.length > 1 && (
                    <IconButton onClick={() => {
                        if (containerIdIndex === containers.length - 1) {
                            setContainerIdIndex(0);
                        } else {
                            setContainerIdIndex(containerIdIndex + 1);
                        }
                    }}>
                        <ChevronRight />
                    </IconButton>
                )}
                <IconButton onClick={handleClose}><Close /></IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    {details && (
                        <>
                            <Grid item xs={12}>
                                <Typography className={classes.details}>{t("containerDetails.containerDetails")}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper elevation={0} className={classes.paper}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography className={classes.detailTitle}>{t("containerDetails.containerSize")}</Typography>
                                            <Typography className={classes.detailValue}>{details.sizeClass}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className={classes.detailTitle}>{t("containerDetails.containerTare")}</Typography>
                                            <Typography className={classes.detailValue}>{details.tare || "-"}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className={classes.detailTitle}>{t("containerDetails.containerType")}</Typography>
                                            <Typography className={classes.detailValue}>{details.typeClass}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <ContainerEvents container={containers[containerIdIndex]} events={events} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default withStyles(style)(
    withTranslation()(withSnackbar(ContainerDetails))
);
