import { createStyles } from "@material-ui/core";

const actionModal = () => createStyles({
    card: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        marginBottom: 20,
        width: 290,
    },
    header: {
        padding: '37px 0 0 20px',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
        marginBottom: 15,
    },
    actions: {
        padding: 0,
    },
    cancel: {
        alignItems: 'center',
        borderColor: '#DADCE3',
        borderRadius: '0 0 0 10px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    ok: {
        alignItems: 'center',
        borderColor: '#DADCE3',
        borderRadius: '0 0 10px 0',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    okButtonTitle: {
        alignItems: 'center',
        borderRadius: '0 0 10px 0',
        color: '#43A5FF',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
        height: '100%',
        padding: '14.5px 0 12.5px',
        width: '100%',
    },
    buttonTitle: {
        alignItems: 'center',
        borderRadius: '0 0 10px 0',
        color: '#43A5FF',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
        height: '100%',
        margin: '16px 0 14px',
    },
});

export default actionModal;
