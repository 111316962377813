import {createStyles} from "@material-ui/core";

const feature = () => createStyles({
    container: {
        position: 'relative',
    },
    featureButton: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        color: '#7E889A',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        height: 50,
        justifyContent: 'flex-start',
        margin: '6px 0',
        padding: 'auto 6px',
        textTransform: 'none',
        width: '100%',
    },
    featureButtonSelected: {
        backgroundColor: '#FFFFFF',
        border: '2px solid #43A5FF',
        borderRadius: 10,
        color: '#7E889A',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        height: 50,
        justifyContent: 'flex-start',
        margin: '6px 0',
        padding: 'auto 6px',
        textTransform: 'none',
        width: '100%',
    },
    addFeatureButton: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        color: '#43A5FF',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        height: 50,
        justifyContent: 'flex-start',
        margin: '6px 0',
        padding: 'auto 6px',
        textTransform: 'none',
        width: '100%',
    },
    addOptionButton: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        color: '#43A5FF',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        height: 50,
        justifyContent: 'flex-start',
        margin: '15px auto 0',
        padding: 'auto 6px',
        textTransform: 'none',
    },
    featureButtonIcon: {
        marginRight: 16,
    },
    featuresList: {
        left: -35,
        position: 'absolute',
        top: 0,
        zIndex: 1,
    },
    featuresContent: {
        backgroundColor: '#F4F5F9',
        borderRadius: 10,
        margin: 15,
        padding: '10px 20px',
    },
    editFeatureContainer: {
        left: -35,
        position: 'absolute',
        top: 0,
        zIndex: 1,
    },
    editFeatureContent: {
        padding: '0 17px 14px',
    },
    editFeatureHeader: {
        alignItems: 'center',
        display: 'flex',
    },
    editFeatureType: {
        color: '#7E889A',
        flex: 1,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        marginLeft: 16,
    },
    editFeatureDelete: {
        backgroundColor: 'rgba(223, 91, 91, 0.12)',
        borderRadius: 5,
        color: '#DF5B5B',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 700,
        padding: '4px 6px',
        textTransform: 'none',
    },
    recoverWayTitle: {
        color: 'rgb(30, 47, 79)',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 500,
        margin: '11px 0 5px',
    },
    optionsTitle: {
        color: 'rgb(30, 47, 79)',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 500,
        margin: '11px 0 0',
    },
});

export default feature;
