import React from "react";
import {DataGrid} from "@material-ui/data-grid";
import {Pagination, PaginationItem} from "@material-ui/lab";
import {Card, withStyles, WithStyles} from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";

import {TruckFrame} from "../../store/site";
import {ModuleType} from "../../store/session";

import style from "./styles/containersGrid";

interface ContainersGridProps extends WithStyles<typeof style>, WithTranslation {
    trucksFrames: TruckFrame[];
    getTrucksFrames: (page: number) => void;
    loading: boolean;
    moduleType: ModuleType;
    rowCount: number;
}

const ContainersGrid = (props: ContainersGridProps) => {
    const { classes, getTrucksFrames, loading, moduleType, rowCount, t, trucksFrames } = props;

    return (
        <Card className={classes.tableContainer} elevation={3}>
            <DataGrid
                disableSelectionOnClick
                disableColumnMenu
                sortingOrder={[]}
                paginationMode="server"
                components={{
                    Pagination: (params) => (
                        <Pagination
                            className={classes.pagination}
                            renderItem={(item) => (
                                <PaginationItem className={classes.paginationItem} {...item} />
                            )}
                            siblingCount={0}
                            count={params.state.pagination.pageCount}
                            page={params.state.pagination.page + 1}
                            onChange={(event, value) => params.api.current.setPage(value - 1)}
                            size="small" />
                    ),
                }}
                headerHeight={50}
                rowHeight={50}
                autoHeight
                className={classes.root}
                rows={trucksFrames}
                rowsPerPageOptions={[15]}
                pageSize={15}
                rowCount={rowCount}
                loading={loading}
                onPageChange={(param) => {
                    getTrucksFrames(param.page);
                }}
                columns={[
                    {
                        field: 'number',
                        headerName: moduleType === ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL ? t("containers.chassisNumber") : t("containers.truckNumber"),
                        flex: 1,
                    },
                    {
                        field: 'enterSiteAt',
                        headerName: t("containers.entryDate"),
                        flex: 1,
                        valueGetter: (params) => {
                            const date = params.row.enterSiteAt;

                            return date ? t("containers.date", { date: new Date(date) }) : "";
                        },
                    },
                    {
                        field: 'entranceContainerSerialNumber',
                        headerName: t("containers.entryContainer"),
                        flex: 1,
                    },
                    {
                        field: 'leaveSiteAt',
                        headerName: t("containers.releaseDate"),
                        flex: 1,
                        valueGetter: (params) => {
                            const date = params.row.leaveSiteAt;

                            return date ? t("containers.date", { date: new Date(date) }) : "";
                        },
                    },
                    {
                        field: 'releaseContainerSerialNumber',
                        headerName: t("containers.releaseContainer"),
                        flex: 1,
                    },
                ]} />
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(ContainersGrid)
);
