import {Store, StoreConfig} from '@datorama/akita';

import {
    CurrentUser, ModuleType, Session, UserConfiguration,
} from "./session.model";
import {UserSite} from "../site";

export function createInitialState(): Session {
    return {
        user: undefined,
        configuration: {
            currentCompanyId: undefined,
            currentSiteId: undefined,
            modulesOrganizations: {},
        },
    };
}

@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<Session> {
    private usersConfigurationsKey = process.env.REACT_APP_USERS_CONFIGURATIONS!;

    constructor() {
        super(createInitialState());
    }

    initUserConfiguration = (user: CurrentUser) => {
        const configsString = localStorage.getItem(this.usersConfigurationsKey);

        let configs: { [key: string]: UserConfiguration } = {};
        if (configsString) configs = JSON.parse(atob(configsString))

        let userConfig: UserConfiguration = configs[user.id] || { ...this.getValue().configuration };

        let currentCompany = user.organizations.find((company) => company.id === userConfig.currentCompanyId);
        // DEFINE CURRENT COMPANY ID IF NO COMPANY SELECTED
        if (!currentCompany) {
            currentCompany = user.organizations[0];
            userConfig.currentCompanyId = currentCompany.id;
        }

        let site = currentCompany.sites.find((site) => site.id === userConfig.currentSiteId);
        // DEFINE CURRENT SITE ID IF NO SITE SELECTED
        if (!site) {
            userConfig.currentSiteId = currentCompany.sites[0].id;
        }

        const allSites = user.organizations.reduce(
            (arr, company) => arr.concat(company.sites),
            [] as UserSite[]
        );
        // REMOVE USELESS MODULES ORGANIZATIONS
        Object.keys(userConfig.modulesOrganizations).forEach((key) => {
            if (!allSites.find((site) => key.indexOf(site.id) === 0)) {
                delete userConfig.modulesOrganizations[key];
            }
        });

        this.update({ configuration: userConfig });

        configs[user.id] = userConfig;

        localStorage.setItem(this.usersConfigurationsKey, btoa(JSON.stringify(configs)));
    }

    updateUserConfiguration = (
        params?: { currentCompanyId?: string, currentSiteId?: string, modulesOrganization?: ModuleType[],
    }) => {
        const user = this.getValue()?.user;
        if (!user) return;

        const configsString = localStorage.getItem(this.usersConfigurationsKey);

        let configs: { [key: string]: UserConfiguration } = {};
        if (configsString) configs = JSON.parse(atob(configsString))

        let userConfig: UserConfiguration = configs[user.id] || { ...this.getValue().configuration };

        if (params?.currentCompanyId) {
            userConfig.currentCompanyId = params.currentCompanyId;
        }

        if (params?.currentSiteId) {
            userConfig.currentSiteId = params.currentSiteId;
        }

        if (params?.modulesOrganization) {
            userConfig.modulesOrganizations[userConfig.currentSiteId!] = params.modulesOrganization;
        }

        this.update({ configuration: userConfig });

        configs[user.id] = userConfig;

        localStorage.setItem(this.usersConfigurationsKey, btoa(JSON.stringify(configs)));
    }

    updateUser = (user: CurrentUser) => {
        this.update({ user });
    }

    setCurrentCompanyId = (companyId: string) => {
        this.updateUserConfiguration({ currentCompanyId: companyId });
    }

    setCurrentSiteId = (siteId: string) => {
        this.updateUserConfiguration({ currentSiteId: siteId });
    }

    updateModulesOrganization = (modulesOrganization: ModuleType[]) => {
       this.updateUserConfiguration({ modulesOrganization });
    }
}

export const sessionStore = new SessionStore();
