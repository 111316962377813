import {Query} from '@datorama/akita';

import {Site} from "./site.model";
import {SiteStore, siteStore} from "./site.store";

export class SiteQuery extends Query<Site> {
    get filters() { return this.getValue().filters; }

    constructor(protected store: SiteStore) {
        super(store);
    }

    getPermissions$ = this.select((state) => state.permissions);

    getFilters$ = this.select((state) => state.filters);
}

export const siteQuery = new SiteQuery(siteStore);
