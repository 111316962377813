import {arrayAdd, arrayRemove, Store, StoreConfig} from '@datorama/akita';
import moment from "moment";

import {SearchType, Site, SitePermission} from "./site.model";

export function createInitialState(): Site {
    return {
        permissions: [],
        filters: {
            startDate: moment().subtract(1, "w").toDate(),
            endDate: moment().toDate(),
            searchType: SearchType.CONTAINER,
            search: "",
        },
    };
}

@StoreConfig({ name: 'site', resettable: true })
export class SiteStore extends Store<Site> {
    constructor() {
        super(createInitialState());
    }

    updateFilters = (filters: { startDate?: Date; endDate?: Date; searchType?: SearchType; search?: string; }) => {
        this.update((state) => ({
            filters: {
                ...state.filters,
                ...filters,
            },
        }));
    }

    updatePermissions = (permissions: SitePermission[]) => {
        this.update({ permissions });
    }

    addPermission = (permission: SitePermission) => {
        this.update((state) => ({
            permissions: arrayAdd(state.permissions, permission),
        }));
    }

    removePermission = (userId: string) => {
        this.update((state) => ({
            permissions: arrayRemove(state.permissions, (p: SitePermission) => p.user.id === userId),
        }));
    }
}

export const siteStore = new SiteStore();
