import React from "react";
import {Avatar, Box, Card, CardActionArea, Typography, WithStyles} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {WithTranslation, withTranslation} from "react-i18next";

import {SitePermission} from "../store/site";

import * as style from './styles/permissionsCards';

interface DashboardPermissionsCardProps extends WithStyles<typeof style.dashboardPermissionsCard>, WithTranslation {
    onPermissionsClick: () => void;
    permissions: SitePermission[];
}

const DashboardPermissionsCard = withStyles(style.dashboardPermissionsCard)(withTranslation()((props: DashboardPermissionsCardProps) => {
    const { classes, onPermissionsClick, permissions, t } = props;

    return (
        <Card elevation={3} className={classes.container} onClick={onPermissionsClick}>
            <CardActionArea className={classes.actionArea}>
                <Typography className={classes.title}>
                    {t("modules.dashboardPermissions")}
                </Typography>
                <Box className={classes.usersContainer}>
                    <Typography className={classes.usersNumber}>
                        {t("modules.usersNumber", { count: permissions.length })}
                    </Typography>
                    <AvatarGroup max={6} classes={{ avatar: classes.moreUsers }}>
                        {permissions.map(({ user }) => (
                            <Avatar key={`${user.firstName} ${user.lastName}`} className={classes.userAvatar}>
                                {user.firstName.toUpperCase()[0] + user.lastName.toUpperCase()[0]}
                            </Avatar>
                        ))}
                    </AvatarGroup>
                </Box>
            </CardActionArea>
        </Card>
    );
}));

export { DashboardPermissionsCard };
