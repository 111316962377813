import React from "react";
import {Box, Card, CardActionArea, CardContent, MenuItem, Typography, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {withSnackbar, WithSnackbarProps} from "notistack";

import ActionModal from "../ActionModal";
import EventNameCard from "./EventNameCard";

import style from "./styles/addEvent";
import {eventsService, EventType, eventTypes} from "../../store/events";

import {getEventTypeName} from "../../utils/events.utils";

enum CardState {
    ADD_EVENT,
    NEW_EVENT,
    EVENT_NAME,
}

interface AddEventProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {}

interface AddEventState {
    selectedEvent: EventType;
    addEventState: CardState;
}


class AddEvent extends React.Component<AddEventProps, AddEventState> {
    constructor(props: AddEventProps) {
        super(props);

        this.state = {
            selectedEvent: EventType.OTHER,
            addEventState: CardState.ADD_EVENT,
        };
    }

    render() {
        const { classes, enqueueSnackbar, t } = this.props;

        const { addEventState, selectedEvent } = this.state;

        switch (addEventState) {
            case CardState.ADD_EVENT:
                return (
                    <Card className={classes.container}>
                        <CardActionArea
                            className={classes.action}
                            onClick={() => {
                                this.setState({ addEventState: CardState.NEW_EVENT });
                            }}>
                            <img src="/images/events/icon_add_feature.svg" alt="" />
                            <Typography className={classes.title}>
                                {t("events.addEvent")}
                            </Typography>
                        </CardActionArea>
                    </Card>
                );
            case CardState.NEW_EVENT:
                return (
                    <ActionModal
                        title={t("events.newEvent")}
                        handleOk={() => {
                            this.setState({ addEventState: CardState.EVENT_NAME });
                        }}
                        handleCancel={() => {
                            this.setState({ addEventState: CardState.ADD_EVENT });
                        }}>
                        <CardContent className={classes.newEventContent}>
                            {eventTypes.map((event) => (
                                <MenuItem
                                    key={event}
                                    component={Box}
                                    selected={selectedEvent === event}
                                    onClick={() => {
                                        this.setState({ selectedEvent: event });
                                    }}
                                    borderTop={1}
                                    className={classes.newEventItem}>
                                    {getEventTypeName(event)}
                                </MenuItem>
                            ))}
                        </CardContent>
                    </ActionModal>
                );
            case CardState.EVENT_NAME:
                return (
                    <EventNameCard
                        title={getEventTypeName(selectedEvent!)}
                        handleOkClick={(eventName) => {
                            eventsService.createNewEvent(selectedEvent!, eventName).subscribe(
                                () => {
                                    this.setState({ addEventState: CardState.ADD_EVENT, selectedEvent: EventType.OTHER });
                                },
                                (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                            );
                        }}
                        handleCancelClick={() => {
                            this.setState({ addEventState: CardState.NEW_EVENT, selectedEvent: EventType.OTHER });
                        }} />
                );
        }
    }
}

export default withStyles(style)(
    withTranslation()(withSnackbar(AddEvent))
);
