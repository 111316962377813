import {from, Observable} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {AxiosResponse} from "axios";

import {sessionQuery, User} from "../session";

import {EventsStore, eventsStore} from './events.store';
import {EventTemplate, EventType, FeatureTemplate} from "./events.model";

import axios, {APIRoutes} from "../../utils/axios.utils";
import SnackError from "../../utils/error.utils";

export class EventsService {
    constructor(private store: EventsStore) {}

    getAllEvents = (): Observable<EventTemplate[]> =>
        sessionQuery.currentIds$
            .pipe(
                switchMap((ids) =>
                    from(axios({...APIRoutes.eventTemplates(ids.site)}))
                ),
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                map((response: AxiosResponse<EventTemplate[]>) => {
                    return response.data;
                }),
                tap((events: EventTemplate[]) => {
                    this.store.set(events);
                }),
            );

    updateEventsPositions = (eventId: string, position: number): Observable<EventTemplate[]> =>
        from(axios({
            ...APIRoutes.updateEventTemplate(sessionQuery.currentSiteId, eventId),
            data: { position }
        }))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        case "EVENT_TEMPLATE_NOT_FOUND":
                            throw new SnackError("errors.eventTemplateNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                switchMap(() => {
                    return this.getAllEvents();
                }),
            );

    createNewEvent = (eventType: EventType, label: string = ""): Observable<EventTemplate[]> =>
        from(axios({
            ...APIRoutes.createEventTemplate(sessionQuery.currentSiteId),
            data: {
              label,
              type: eventType,
              features: [],
              attributions: [],
            }
        }))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                switchMap(() => {
                    return this.getAllEvents();
                }),
            );

    deleteEvent = (eventId: string): Observable<EventTemplate[]> =>
        from(axios({...APIRoutes.deleteEventTemplate(sessionQuery.currentSiteId, eventId)}))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        case "EVENT_TEMPLATE_NOT_FOUND":
                            throw new SnackError("errors.eventTemplateNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                switchMap(() => {
                    return this.getAllEvents();
                }),
            );


    changeEventName = (eventId: string, eventName: string = ""): Observable<EventTemplate> =>
        from(axios({
          ...APIRoutes.updateEventTemplate(sessionQuery.currentSiteId, eventId),
          data: { label: eventName }
        }))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        case "EVENT_TEMPLATE_NOT_FOUND":
                            throw new SnackError("errors.eventTemplateNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                map((response: AxiosResponse<EventTemplate>) => {
                    return response.data;
                }),
                tap((event: EventTemplate) => {
                    this.store.upsert(eventId, event);
                }),
            );

    addFeature = (eventId: string, feature: FeatureTemplate): Observable<any> =>
        from(axios({
            ...APIRoutes.addFeature(sessionQuery.currentSiteId, eventId),
            data: feature,
        }))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        case "EVENT_TEMPLATE_NOT_FOUND":
                            throw new SnackError("errors.eventTemplateNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                map((response: AxiosResponse<FeatureTemplate>) => {
                    return response.data;
                }),
                tap((newFeature: FeatureTemplate) => {
                    this.store.addFeature(eventId, newFeature);
                }),
            );

    updateFeature = (eventId: string, feature: FeatureTemplate): Observable<any> =>
        from(axios({
            ...APIRoutes.updateFeature(sessionQuery.currentSiteId, eventId, feature.id!),
            data: feature,
        }))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                          throw new SnackError("errors.siteNotFound", "error");
                        case "EVENT_TEMPLATE_NOT_FOUND":
                          throw new SnackError("errors.eventTemplateNotFound", "error");
                        default:
                          throw new SnackError("errors.serverError", "error");
                    }
                }),
                map((response: AxiosResponse<FeatureTemplate>) => {
                    return response.data;
                }),
                tap((updatedFeature: FeatureTemplate) => {
                    this.store.updateFeature(eventId, updatedFeature);
                }),
            );

    removeFeature = (eventId: string, featureId: string): Observable<any> =>
        from(axios({...APIRoutes.removeFeature(sessionQuery.currentSiteId, eventId, featureId)}))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        case "EVENT_TEMPLATE_NOT_FOUND":
                            throw new SnackError("errors.eventTemplateNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                tap(() => {
                    this.store.removeFeature(eventId, featureId);
                }),
            );

    addAttribution = (eventId: string, email: string): Observable<string | undefined> =>
        from(axios({
            ...APIRoutes.addAttribution(sessionQuery.currentSiteId, eventId),
            data: { email },
        }))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        case "EVENT_TEMPLATE_NOT_FOUND":
                            throw new SnackError("errors.eventTemplateNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                map((response: AxiosResponse<User | undefined>) => {
                    return response.data;
                }),
                map((user?: User ) => {
                    if (user) {
                        this.store.addAttribution(eventId, user);
                    } else {
                        return "success.mailSent";
                    }
                }),
            );

    removeAttribution = (eventId: string, userId: string): Observable<AxiosResponse> =>
        from(axios({...APIRoutes.removeAttribution(sessionQuery.currentSiteId, eventId, userId)}))
            .pipe(
                catchError((err) => {
                    switch (err.response?.data?.errorMessage) {
                        case "SITE_NOT_FOUND":
                            throw new SnackError("errors.siteNotFound", "error");
                        case "EVENT_TEMPLATE_NOT_FOUND":
                            throw new SnackError("errors.eventTemplateNotFound", "error");
                        case "USER_NOT_FOUND":
                            throw new SnackError("errors.userNotFound", "error");
                        default:
                            throw new SnackError("errors.serverError", "error");
                    }
                }),
                tap(() => {
                    this.store.removeAttribution(eventId, userId);
                }),
            );
}

export const eventsService = new EventsService(eventsStore);
