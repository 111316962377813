import {VariantType} from "notistack";

class SnackError extends Error {
    private readonly key: string;
    private readonly variant: VariantType;

    constructor(key: string, variant: VariantType, ...params: any[]) {
        super(...params);

        this.name = 'SnackError';

        this.key = key;
        this.variant = variant;
    }
}

export default SnackError;
