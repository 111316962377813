import {SearchType} from "../store/site";
import I18n from "./i18n.utils";

export const searchTypes = [
	SearchType.CONTAINER,
	SearchType.TRUCK,
	SearchType.CHASSIS
];

export const getFilterName = (searchType: SearchType): string => {
	switch (searchType) {
		case SearchType.CONTAINER:
			return I18n.t("dashboard.containerNumber");
		case SearchType.TRUCK:
			return I18n.t("dashboard.truckNumber");
		case SearchType.CHASSIS:
			return I18n.t("dashboard.chassisNumber");
		default:
			return "";
	}
}
