import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Grid, IconButton, Typography, withStyles, WithStyles} from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";
import {withSnackbar, WithSnackbarProps} from "notistack";
import {Subscription} from "rxjs";

import {getModuleName} from "../../../utils/modules.utils";
import {ModuleType} from "../../../store/session";
import {Container, ContainerDetailsParams, Filters, siteQuery, siteService, TruckFrame} from "../../../store/site";

import {AutoComplete, DateTextField} from "../../../components/TextFields";
import ContainersGrid from "../../../components/containers/ContainersGrid";
import {LocationOnSite} from "../../../components/dashboard";
import ContainerDetails from "../ContainerDetails/ContainerDetails";
import ContainersGridTruckOrChassis from "../../../components/containers/ContainersGridTruckOrChassis";

import style from "./styles/containers";
import {downloadCSV} from "../../../utils/documents.utils";

interface ContainersProps extends RouteComponentProps, WithStyles<typeof style>, WithTranslation, WithSnackbarProps {}

interface ContainersState {
    filters: Filters;

    params?: {
        moduleType: ModuleType;
        eventTemplateId: string;
        eventTemplateLabel: string;
    }

    loading: boolean;
    containers: Container[];
    trucksFrames: TruckFrame[];
    totalRow: number;

    openContainerDetailsForId?: ContainerDetailsParams;
}

class Containers extends React.Component<ContainersProps, ContainersState> {
    private _filters?: Subscription = undefined;

    constructor(props: ContainersProps) {
        super(props);

        this.state = {
            filters: siteQuery.filters,

            params: undefined,

            loading: true,
            containers: [],
            trucksFrames: [],
            totalRow: 0,

            openContainerDetailsForId: undefined,
        };
    }

    getContainers = (page: number = 0) => {
        const { enqueueSnackbar, t } = this.props;

        const { params } = this.state;

        this.setState({ loading: true });

        switch (params!.moduleType) {
            case ModuleType.LAST_CONTAINERS_EVENTS:
                siteService.fetchContainersForEvent(params!.eventTemplateId, page).subscribe(
                    (containersPaging) => {
                        this.setState({ containers: containersPaging.results, totalRow: containersPaging.totalItemCount, loading: false });
                    },
                    (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
                );
                return ;
            case ModuleType.LOCATION_ON_SITE:
                siteService.fetchContainersWithLocation(page).subscribe(
                    (containersPaging) => {
                        this.setState({ containers: containersPaging.results, totalRow: containersPaging.totalItemCount, loading: false });
                    },
                    (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
                );
                return ;
            case ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL:
                siteService.fetchTrucks(page).subscribe(
                    (trucksFramesPaging) => {
                        this.setState({ trucksFrames: trucksFramesPaging.results, totalRow: trucksFramesPaging.totalItemCount, loading: false });
                    },
                    (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
                );
                return ;
            case ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL:
                siteService.fetchChassis(page).subscribe(
                    (trucksFramesPaging) => {
                        this.setState({ trucksFrames: trucksFramesPaging.results, totalRow: trucksFramesPaging.totalItemCount, loading: false });
                    },
                    (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
                );
                return ;
            default:
                siteService.fetchContainers(page).subscribe(
                    (containersPaging) => {
                        this.setState({ containers: containersPaging.results, totalRow: containersPaging.totalItemCount, loading: false });
                    },
                    (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
                );
                return ;
        }
    }

    componentDidMount() {
        const { history } = this.props;

        const search = history.location.search.slice(1, history.location.search.length);
        const splitSearch = search.split("&");

        const params: { [key: string]: string } = {};
        splitSearch.forEach((param) => {
            const splitParam = param.split("=");

            params[splitParam[0]] = decodeURI(splitParam[1]);
        });

        try {
            this.setState({
                params: {
                    moduleType: params.module as ModuleType,
                    eventTemplateId: params.eventTemplateId,
                    eventTemplateLabel: params.eventTemplateLabel,
                },
            });
        } catch {
            history.push("/");
        }

        this._filters = siteQuery.getFilters$.subscribe((filters) => {
            this.setState({ filters }, () => this.getContainers());
        });
    }

    componentWillUnmount() {
        if (this._filters) this._filters.unsubscribe();
    }

    handleFilterChange = (name: string) => (value: any) => {
        const { filters } = this.state;
        this.setState({ filters: { ...filters, [name]: value } })
    }

    render() {
        const { classes, enqueueSnackbar, t } = this.props;

        const { containers, filters, loading, openContainerDetailsForId, params, totalRow, trucksFrames } = this.state;

        if (!params) return null;

        return (
            <>
                <div className={classes.container}>
                    <Grid container className={classes.gridContent} spacing={3} alignContent="flex-start">
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                {t(getModuleName(params.moduleType))}
                                {params.moduleType === ModuleType.LAST_CONTAINERS_EVENTS && ` - ${params.eventTemplateLabel}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.searchContainer}>
                            {params.moduleType === ModuleType.LAST_CONTAINERS_EVENTS && (
                                <>
                                    <div className={classes.dateContainer}>
                                        <DateTextField
                                            date={filters.startDate}
                                            handleChange={(date) => {
                                                if (date) {
                                                    const endDate = date.isAfter(filters.endDate) ? date.toDate() : filters.endDate;
                                                    siteService.updateFilters({ startDate: date.toDate() || new Date(), endDate });
                                                }
                                            }}
                                            label={t("input.dateFrom")} />
                                    </div>
                                    <div className={classes.dateContainer}>
                                        <DateTextField
                                            date={filters.endDate}
                                            handleChange={(date) => {
                                                if (date) {
                                                    const startDate = date.isBefore(filters.startDate) ? date.toDate() : filters.startDate
                                                    siteService.updateFilters({ endDate: date.toDate() || new Date(), startDate });
                                                }
                                            }}
                                            label={t("input.dateTo")} />
                                    </div>
                                </>
                            )}
                            <div className={classes.selectContainer}>
                                <Typography className={classes.containerNumber}>
                                    {params.moduleType === ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL && t("dashboard.truckNumber")}
                                    {params.moduleType === ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL && t("dashboard.chassisNumber")}
                                    {(params.moduleType !== ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL && params.moduleType !== ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL) && (
                                        t("dashboard.containerNumber")
                                    )}
                                </Typography>
                                <div className={classes.selectDivider} />
                                <AutoComplete
                                    handleChange={(search) => {
                                        siteService.updateFilters({ search: search || undefined });
                                    }}
                                    value={filters.search}
                                    placeholder={t("input.search")}
                                    options={[]} />
                            </div>
                        </Grid>
                        <Grid item xs={4} className={classes.actionsContainer}>
                            <IconButton
                                className={classes.exportDataButton}
                                onClick={() => {
                                    let csvObservable;

                                    switch (params.moduleType as ModuleType) {
                                        case ModuleType.LAST_CONTAINERS_EVENTS:
                                            csvObservable = siteService.exportContainersForEvent(params.eventTemplateId, filters);
                                            break ;
                                        case ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL:
                                            csvObservable = siteService.exportTrucks();
                                            break ;
                                        case ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL:
                                            csvObservable = siteService.exportChassis();
                                            break ;
                                        default:
                                            csvObservable = siteService.exportContainers();
                                            break ;
                                    }

                                    if (csvObservable) csvObservable.subscribe(
                                        (res) => {
                                            downloadCSV(res);
                                        },
                                        (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
                                    )
                                }}>
                                <img src="/images/dashboard/icon_export_data.svg" alt="" />
                            </IconButton>
                        </Grid>
                        {params.moduleType === ModuleType.LOCATION_ON_SITE && (
                            <Grid item xs={12}>
                                <LocationOnSite filters={filters} containers={containers} />
                            </Grid>
                        )}
                    </Grid>
                    <div className={classes.tableContainer}>
                        {(params.moduleType === ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL || params.moduleType === ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL) ? (
                            <ContainersGridTruckOrChassis
                                trucksFrames={trucksFrames}
                                loading={loading}
                                moduleType={params.moduleType}
                                rowCount={totalRow}
                                getTrucksFrames={this.getContainers} />
                        ) : (
                            <ContainersGrid
                                containers={containers}
                                loading={loading}
                                rowCount={totalRow}
                                getContainers={this.getContainers}
                                onContainerClick={(container) => {
                                    this.setState({
                                        openContainerDetailsForId: {
                                            containerId: container.containerId, serialNumber: container.serialNumber, cycleId: container.cycleId
                                        },
                                    });
                                }}/>
                        )}
                    </div>
                </div>
                {openContainerDetailsForId && (
                    <ContainerDetails
                        containers={[openContainerDetailsForId]}
                        handleClose={() => {
                            this.setState({ openContainerDetailsForId: undefined });
                        }} />
                )}
            </>
        );
    }
}

export default withStyles(style)(
    withTranslation()(
        withRouter(withSnackbar(Containers))
    )
);

