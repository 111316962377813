import { createStyles } from "@material-ui/core";

const eventCard = () => createStyles({
    container: {
        padding: '0 10px',
        position: 'relative',
    },
    card: {
        backgroundColor: 'white',
        borderRadius: 10,
        overflow: "inherit",
        paddingBottom: 5,
        width: 290,
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    lockIcon: {
        height: 20,
        marginBottom: 3,
        marginRight: 7,
        width: 20,
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 700,
        width: '80%',
    },
    header: {
      padding: '23px 0 5px 20px',
    },
    action: {
        position: 'absolute',
        right: 10,
        top: 7,
    },
    features: {
        backgroundColor: '#F4F5F9',
        borderRadius: 10,
        margin: 15,
        padding: '10px 20px',
    },
    attributions: {
        backgroundColor: '#F4F5F9',
        borderRadius: 10,
        margin: 15,
        padding: '10px 20px',
    },
    contentTitle: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
        marginBottom: 10,
    },
    eventOption: {
        position: 'absolute',
        right: 10,
        top: 0,
    },
    optionsMenu: {
        borderRadius: 10,
        padding: '0px 0px 0px 15px',
    },
    optionsItem: {
        borderColor: 'rgba(151,151,151, 0.2)',
        color: '#43A5FF',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 700,
        padding: '15px 0',
        minWidth: 150,
    },
    editEvent: {
        position: 'absolute',
        right: 10,
        top: 0,
    },
    eventButtonAdd: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        color: '#43A5FF',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        height: 50,
        justifyContent: 'flex-start',
        margin: '6px 0',
        padding: 'auto 6px',
        textTransform: 'none',
        width: '100%',
    },
    eventButtonOperators: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        color: '#1E2F4F',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
        height: 50,
        justifyContent: 'space-between',
        margin: '6px 0',
        padding: 'auto 16px',
        textTransform: 'none',
        width: '100%'
    },
    addEventButton: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        color: '#43A5FF',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
        height: 100,
        margin: '6px 0',
        padding: 'auto 6px',
        textTransform: 'none',
        width: 290,
    },
    eventBadge: {
        backgroundColor: '#F4F5F9',
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
        height: 30,
        width: 30,
    },
    eventIcon: {
        marginRight: 16,
    },
});

export default eventCard;
