import {QueryEntity} from '@datorama/akita';
import {map} from "rxjs/operators";

import {EventsState, eventsStore, EventsStore} from './events.store';

export class EventsQuery extends QueryEntity<EventsState> {
    constructor(protected store: EventsStore) {
        super(store);
    }

    sortedEvents$ = this.selectAll()
        .pipe(
            map((events) => {
                return events.sort((a, b) =>  a.position - b.position);
            }),
        );
}

export const eventsQuery = new EventsQuery(eventsStore);
