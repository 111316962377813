import React, {useEffect, useState} from "react";
import {Box, Card, CardActionArea, Grid, Typography, WithStyles} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";
import { Doughnut } from 'react-chartjs-2';
import {withSnackbar, WithSnackbarProps} from "notistack";
import {switchMap} from "rxjs/operators";

import {Filters, siteService, StatsStillOnSite} from "../../store/site";

import style from './styles/containersStillOnSite';
import {sessionQuery} from "../../store/session";

interface ContainersStillOnSiteProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    filters: Filters;
    onModuleClick: () => void;
}

const doughnutOptions = {
    legend: { display: false },
    elements: {
        arc: {
            borderWidth: 0,
        },
    },
    cutoutPercentage: 80,
    circumference: Math.PI,
    rotation: Math.PI,
};

const ContainersStillOnSite = (props: ContainersStillOnSiteProps) => {
    const { classes, enqueueSnackbar, filters, onModuleClick, t } = props;

    const [stats, setStats] = useState<StatsStillOnSite | undefined>();

    useEffect(() => {
        const stillOnSite = sessionQuery.currentSite$.pipe(
            switchMap(() => {
                return siteService.fetchContainersStillOnSite(filters);
            })
        ).subscribe(
            (stats) => {
                setStats(stats);
            },
            (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
        );

        return () => stillOnSite.unsubscribe();
   }, [enqueueSnackbar, filters, t]);

    const data = {
        labels: stats ? [t('modules.sealed'), t('modules.notSealed')] : [],
        datasets: stats ? [{ data: [stats.sealed, stats.unsealed], backgroundColor: [ '#60B690', '#1C9195' ] }] : [],
    };

    return (
        <Card elevation={3} className={classes.container} onClick={onModuleClick}>
            {stats && (
                <CardActionArea className={classes.actionArea}>
                    <Box className={classes.topContainer}>
                        <Typography className={classes.title}>
                            {t('modules.containersStillOnSite')}
                        </Typography>
                        <div className={classes.chartContainer}>
                            <div>
                                <Doughnut height={150} width={250} data={data} options={doughnutOptions}/>
                            </div>
                            <div className={classes.totalContainers}>
                                <Typography className={classes.containersNumber}>
                                    {stats.total}
                                </Typography>
                                <Typography className={classes.containersText}>
                                    {t('modules.containers')}
                                </Typography>
                            </div>
                        </div>
                    </Box>
                    <Grid container>
                        <Grid item xs={6} component={Box} borderTop={1} borderRight={1} className={classes.legendContainer}>
                            <Typography className={classes.sealedNumber}>
                                {stats.sealed}
                            </Typography>
                            <Typography className={classes.containersText}>
                                {t('modules.sealed')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} component={Box} borderTop={1} className={classes.legendContainer}>
                            <Typography className={classes.notSealedNumber}>
                                {stats.unsealed}
                            </Typography>
                            <Typography className={classes.containersText}>
                                {t('modules.notSealed')}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActionArea>
            )}
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(withSnackbar(ContainersStillOnSite))
);
