import React from "react";
import {Grid, IconButton, WithStyles, withStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {withSnackbar, WithSnackbarProps} from "notistack";
import {of, Subscription} from "rxjs";
import {switchMap, tap} from "rxjs/operators";

import {ModuleType, sessionQuery} from "../../../store/session";
import {
    ContainerDetailsParams,
    Filters,
    Role,
    SearchType,
    SitePermission,
    siteQuery,
    siteService,
    UserSite
} from "../../../store/site";
import {getFilterName, searchTypes} from "../../../utils/filters.utils";
import {getEventTypeName} from "../../../utils/events.utils";

import {DashboardPermissionsCard} from "../../../components/PermissionsCards";
import {
    ChassisTimeLapseSinceArrival,
    ContainersStillOnSite,
    LastContainersEvents,
    LocationOnSite,
    ManageModules,
    ManagePermissions,
    SiteProcess,
    TimeLapseSinceArrivalOnSite,
    TruckTimeLapseSinceArrival,
    TurnTimeOfEachEvent,
} from '../../../components/dashboard';
import {AutoComplete, SelectColor, SelectTextField} from "../../../components/TextFields";
import ContainerDetails from "../ContainerDetails/ContainerDetails";

import style from './styles/dashboard';

enum OpenModalState {
    NONE,
    MANAGE_MODULES,
    MANAGE_PERMISSIONS,
    CONTAINER_DETAILS,
}

interface DashboardProps extends RouteComponentProps, WithStyles<typeof style>, WithTranslation, WithSnackbarProps {}

interface DashboardState {
    openModalState: OpenModalState;
    containers: ContainerDetailsParams[];

    site: UserSite;
    modulesOrganization: ModuleType[];

    filters: Filters;
    autocomplete: string[];

    permissions: SitePermission[];
}

class Dashboard extends React.Component<DashboardProps, DashboardState> {
    private _currentSite?: Subscription = undefined;
    private _modulesOrganization?: Subscription = undefined;
    private _permissions?: Subscription = undefined;
    private _filters?: Subscription = undefined;

    constructor(props: DashboardProps) {
        super(props);

        this.state = {
            openModalState: OpenModalState.NONE,
            containers: [],

            site: sessionQuery.currentSite,
            modulesOrganization: [],

            filters: siteQuery.filters,
            autocomplete: [],

            permissions: [],
        };
    }

    componentDidMount() {
        const { enqueueSnackbar, t } = this.props;

        this._currentSite = sessionQuery.currentSite$.subscribe((site) => {
            this.setState({ site });
        });
        this._modulesOrganization = sessionQuery.modulesOrganizationForSite$.subscribe((modulesOrganization) => {
            this.setState({ modulesOrganization });
        });

        siteService.fetchSitePermissions().subscribe(
            () => {},
            (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
        );

        this._permissions = siteQuery.getPermissions$.subscribe((permissions) => {
            this.setState({ permissions });
        });

        this._filters = siteQuery.getFilters$
            .pipe(
                tap((filters) => {
                    this.setState({ filters });
                }),
                switchMap((filters) => {
                    return filters.search ? siteService.fetchAutocompleteResults() : of({ results: [] });
                }),
            )
            .subscribe(
                (autocomplete) => {
                    this.setState({ autocomplete: autocomplete.results });
                },
                (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
            );
    }

    componentWillUnmount() {
        if (this._currentSite) this._currentSite.unsubscribe();
        if (this._modulesOrganization) this._modulesOrganization.unsubscribe();
        if (this._permissions) this._permissions.unsubscribe();
        if (this._filters) this._filters.unsubscribe();
    }

    handleOpenModalState = (openModalState: OpenModalState, containers?: ContainerDetailsParams[]) => {
        this.setState({ openModalState, containers: containers || [] });
    }

    renderModal = () => {
        const { containers, modulesOrganization, openModalState } = this.state;

        switch (openModalState) {
            case OpenModalState.MANAGE_MODULES:
                return (
                    <ManageModules
                        modulesOrganization={modulesOrganization}
                        handleClose={() => {
                            this.handleOpenModalState(OpenModalState.NONE);
                        }} />
                );
            case OpenModalState.MANAGE_PERMISSIONS:
                return (
                    <ManagePermissions
                        handleClose={() => {
                            this.handleOpenModalState(OpenModalState.NONE);
                        }} />
                );
            case OpenModalState.CONTAINER_DETAILS:
                return (
                    <ContainerDetails
                        containers={containers}
                        handleClose={() => {
                            this.handleOpenModalState(OpenModalState.NONE);
                        }} />
                )
            default:
                return null;
        }
    }

    renderModule = (moduleType: ModuleType) => {
        const { history } = this.props;

        const { filters } = this.state;

        switch (moduleType) {
            case ModuleType.CONTAINERS_STILL_ON_SITE:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <ContainersStillOnSite
                            filters={filters}
                            onModuleClick={() => {
                                history.push({ pathname: "/containers", search: `module=${moduleType}` });
                            }} />
                    </Grid>
                );
            case ModuleType.LOCATION_ON_SITE:
                return (
                    <Grid item xs={8} key={`dashboard-${moduleType}`}>
                        <LocationOnSite
                            filters={filters}
                            onClusterClick={(containers) => {
                                this.handleOpenModalState(OpenModalState.CONTAINER_DETAILS, containers);
                            }}
                            onModuleClick={() => {
                                history.push({ pathname: "/containers", search: `module=${moduleType}` });
                            }} />
                    </Grid>
                );
            case ModuleType.LAST_CONTAINERS_EVENTS:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <LastContainersEvents
                            filters={filters}
                            onEventClick={(eventCount) => {
                                const eventTypeName = getEventTypeName(eventCount.eventTemplateType);
                                const label = eventCount.eventTemplateName !== "" ? eventCount.eventTemplateName : eventTypeName;

                                history.push({
                                    pathname: "/containers",
                                    search: `module=${moduleType}&eventTemplateId=${eventCount.eventTemplateId}&eventTemplateLabel=${label}`,
                                });
                            }} />
                    </Grid>
                );
            case ModuleType.TIME_LAPSE_SINCE_ARRIVAL_ON_SITE:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <TimeLapseSinceArrivalOnSite
                            filters={filters}
                            onModuleClick={() => {
                                history.push({ pathname: "/containers", search: `module=${moduleType}` });
                            }} />
                    </Grid>
                );
            case ModuleType.TURN_TIME_OF_EACH_EVENT:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <TurnTimeOfEachEvent
                            onPointClick={(containers) => {
                                this.handleOpenModalState(OpenModalState.CONTAINER_DETAILS, containers);
                            }}
                            filters={filters} />
                    </Grid>
                );
            case ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <TruckTimeLapseSinceArrival
                            filters={filters}
                            onModuleClick={() => {
                                history.push({ pathname: "/containers", search: `module=${moduleType}` });
                            }} />
                    </Grid>
                );
            case ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL:
                return (
                    <Grid item xs={4} key={`dashboard-${moduleType}`}>
                        <ChassisTimeLapseSinceArrival
                            filters={filters}
                            onModuleClick={() => {
                                history.push({ pathname: "/containers", search: `module=${moduleType}` });
                            }} />
                    </Grid>
                );
        }
    }

    render() {
        const { classes, history, t } = this.props;

        const { autocomplete, filters, modulesOrganization, permissions, site } = this.state;

        return (
            <div className={classes.container}>
                <Grid container className={classes.gridContent} spacing={3} alignItems="stretch">
                    {site.role !== Role.VIEWER && (
                        <Grid item xs={9}>
                            <SiteProcess handleClick={() => { history.push("/events"); }} />
                        </Grid>
                    )}
                    {site.role !== Role.VIEWER && (
                        <Grid item xs={3}>
                            <DashboardPermissionsCard
                                permissions={permissions}
                                onPermissionsClick={() => {
                                    this.handleOpenModalState(OpenModalState.MANAGE_PERMISSIONS);
                                }} />
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <div className={classes.selectContainer}>
                            <SelectTextField
                                selected={filters.searchType}
                                selectColor={SelectColor.DARK_GRAY}
                                handleChange={(evt) => {
                                    siteService.updateFilters({ searchType: evt.target.value as SearchType });
                                }}
                                data={searchTypes.map((s) => ({ label: getFilterName(s), value: s }))} />
                            <div className={classes.divider} />
                            <AutoComplete
                                handleChange={(search) => {
                                    siteService.updateFilters({ search: search || undefined });
                                }}
                                value={filters.search}
                                placeholder={t("input.search")}
                                options={autocomplete} />
                        </div>
                    </Grid>
                    <Grid item xs={8} className={classes.actionsContainer}>
                        <IconButton className={classes.exportDataButton}>
                            <img src="/images/dashboard/icon_export_data.svg" alt="" />
                        </IconButton>
                        <IconButton
                            className={classes.editButton}
                            onClick={() => {
                                this.handleOpenModalState(OpenModalState.MANAGE_MODULES);
                            }}>
                            <img src="/images/dashboard/icon_edit.svg" alt="" />
                        </IconButton>
                    </Grid>
                    {modulesOrganization
                        .filter((m) => {
                            if (!filters.search)
                                return true;

                            if (filters.searchType === SearchType.TRUCK) {
                                return m === ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL;
                            }
                            if (filters.searchType === SearchType.CHASSIS) {
                                return m === ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL;
                            }
                            return m !== ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL && m !== ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL;
                        })
                        .map(this.renderModule)
                    }
                </Grid>
                {this.renderModal()}
            </div>
        );
    }
}

export default withStyles(style)(
    withTranslation()(
        withRouter(withSnackbar(Dashboard))
    )
);
