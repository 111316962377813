import React, {useEffect, useState} from "react";
import {Button, Card, CardHeader, withStyles, WithStyles} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import {withTranslation, WithTranslation} from "react-i18next";
import {Feature} from "ol";
import {Point} from "ol/geom";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Fill, Style, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";
import {Cluster} from "ol/source";
import {transform} from "ol/proj";
import {withSnackbar, WithSnackbarProps} from "notistack";
import {switchMap, tap} from "rxjs/operators";
import {of} from "rxjs";

import Map from "../Map";

import style from "./styles/locationOnSite";
import {sessionQuery} from "../../store/session";
import {Container, ContainerDetailsParams, Filters, siteService, StatsLastLocation, UserSite} from "../../store/site";
import {getEventTypeStyle} from "../../utils/events.utils";

interface LocationOnSiteProps extends WithTranslation, WithStyles<typeof style>, WithSnackbarProps {
    containers?: Container[];
    filters: Filters;
    onClusterClick?: (containers: ContainerDetailsParams[]) => void;
    onModuleClick?: () => void;
}

const LocationOnSite = (props: LocationOnSiteProps) => {
    const { classes, containers, enqueueSnackbar, filters, onClusterClick, onModuleClick, t } = props;

    const [lastLocations, setLastLocations] = useState<StatsLastLocation[]>(
        containers
            ? containers.map((c) => ({ ...c, id: c.containerId, serialNumber: c.serialNumber }))
            : []
    );
    const [site, setSite] = useState<UserSite | undefined>(undefined);

    useEffect(() => {
        const obs = sessionQuery.currentSite$.pipe(
            tap((site) => {
                setSite(site);
            }),
            switchMap(() => {
                if (containers) {
                    return of(
                        containers
                        ? containers.map((c) => ({ ...c, id: c.serialNumber }))
                        : []
                    );
                } else {
                    return siteService.fetchLocationOnSite(filters);
                }
            }),
        ).subscribe(
            (stats: StatsLastLocation[]) => {
                setLastLocations(stats);
            },
            (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
        );

        return () => obs.unsubscribe();
    }, [containers, enqueueSnackbar, filters, t]);

    const features = lastLocations.map((location) => {
        const coordinates = transform([location.longitude, location.latitude], 'EPSG:4326', 'EPSG:3857');

        const feature = new Feature({
            geometry: new Point(coordinates),
            attributes: { ...location },
        });

        feature.setStyle([
            new Style({
                image: new CircleStyle({
                    radius: 10 + (10 / 2),
                    fill: new Fill({
                        color: getEventTypeStyle(location.lastEventType).color,
                    }),
                }),
            })
        ]);

        return feature;
    });

    const source = new VectorSource({
        features,
    });

    const clusterSource = new Cluster({
        distance: parseInt("50", 10),
        source,
    });

    const clusters = new VectorLayer({
        source: clusterSource,
        style: (feature) => {
            let size = feature.get('features').length;

            if (size === 1) {
                return feature.get("features")[0].getStyle();
            }

            return new Style({
                image: new CircleStyle({
                    radius: 10 + (10 * (size - 1) / 2),
                    fill: new Fill({
                        color: '#43A5FF',
                    }),
                }),
                text: new Text({
                    text: size > 1 ? size.toString() : null,
                    fill: new Fill({
                        color: 'white',
                    }),
                    scale: (size - 1) / 2,
                }),
            });
        },
    });

    return (
        <Card elevation={3} className={classes.container} onClick={() => {}}>
            {site && (
                <Map
                    center={transform([site.location.longitude, site.location.latitude], 'EPSG:4326', 'EPSG:3857')}
                    onClusterClick={onClusterClick ? (data: StatsLastLocation[]) => {
                        let containers = data.map((d) => ({ containerId: d.id, serialNumber: d.serialNumber, cycleId: d.cycleId }));
                        containers = containers.filter((container) => container.containerId);

                        if (containers.length) {
                            onClusterClick(containers);
                        }
                    } : undefined}
                    clusters={clusters} />
            )}
            <CardHeader
                className={classes.header}
                classes={{
                    title: classes.title,
                }}
                action={(
                    onModuleClick ? (
                        <Button
                            onClick={() => {
                                onModuleClick();
                            }}
                            className={classes.goToContainersList}>
                            Go to containers list
                            <ArrowRightAltIcon className={classes.goToContainerListIcon} />
                        </Button>
                    ) : null
                )}
                title={onModuleClick ? t("modules.locationOnSite") : undefined} />
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(withSnackbar(LocationOnSite))
);
