import React from "react";
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardHeader,
    Typography, withStyles,
    WithStyles
} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";

import style from "./styles/actionModal";

interface ActionModalProps extends WithStyles<typeof style>, WithTranslation {
    children: React.ReactNode;
    handleCancel: () => void;
    handleOk: () => void;
    title: string;
}

const ActionModal = (props: ActionModalProps) => {
    const { children, classes, handleCancel, handleOk, t, title } = props;

    return (
        <Card className={classes.card} elevation={8}>
            <CardHeader
                className={classes.header}
                titleTypographyProps={{ classes: { root: classes.title } }}
                title={title} />
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    handleOk();
                }}>
                {children}
                <CardActions className={classes.actions} disableSpacing>
                    <CardActionArea
                        className={classes.cancel}
                        component={Box}
                        onClick={handleCancel}
                        borderTop={1}
                        borderRight={1}>
                        <Typography className={classes.buttonTitle}>
                            {t("events.cancel")}
                        </Typography>
                    </CardActionArea>
                    <CardActionArea className={classes.ok} component={Box} borderTop={1}>
                        <Typography component={Button} type="submit" className={classes.okButtonTitle}>
                            {t("events.ok")}
                        </Typography>
                    </CardActionArea>
                </CardActions>
            </form>
        </Card>
    );
}

export default withStyles(style)(
    withTranslation()(ActionModal)
);
