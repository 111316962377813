import {from, Observable} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {AxiosResponse} from "axios";
import moment from "moment";

import {sessionQuery} from "../session";
import {
	AutoCompleteResult, ContainerDetailsParams,
	ContainersPaging,
	EventCount,
	Filters, LightContainer,
	SearchType,
	SitePermission,
	SiteProcessStep,
	StatsLastLocation,
	StatsStillOnSite,
	TimeLapseOnSite,
	TruckFrameTimeLapseOnSite,
	TrucksFramesPaging, TurnTimeEvent
} from "./site.model";
import {siteStore, SiteStore} from "./site.store";
import {Event, EventTemplate} from "../events";

import axios, {APIRoutes} from "../../utils/axios.utils";
import SnackError from "../../utils/error.utils";
import {siteQuery} from "./site.query";

export class SiteService {
	constructor(private store: SiteStore) {}

	fetchSiteProcess = (): Observable<SiteProcessStep[]> =>
		from(axios({...APIRoutes.eventTemplates(sessionQuery.currentSiteId)}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<EventTemplate[]>) => {
					return response.data.map((event, index) => ({
						id: event.id,
						label: event.label,
						type: event.type,
						index: index + 1,
					}));
				}),
			);

	fetchSitePermissions = (): Observable<SitePermission[]> =>
		sessionQuery.currentSite$
			.pipe(
				switchMap(() => {
					return from(axios({...APIRoutes.sitePermissions(sessionQuery.currentSiteId)}));
				}),
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<SitePermission[]>) => {
					return response.data;
				}),
				tap((permissions: SitePermission[]) => {
					this.store.updatePermissions(permissions);
				}),
			);

	private autoCompleteRequestBySearchType= {
		[SearchType.CONTAINER]: APIRoutes.autoCompleteContainers,
		[SearchType.TRUCK]: APIRoutes.autoCompleteTrucks,
		[SearchType.CHASSIS]: APIRoutes.autoCompleteChassis,
	};

	fetchAutocompleteResults = (): Observable<AutoCompleteResult> => {
		const filters = siteQuery.filters;

		return from(axios({
			...this.autoCompleteRequestBySearchType[filters.searchType](sessionQuery.currentSiteId),
			params: {
				partialContainerNumber: filters.searchType === SearchType.CONTAINER ? filters.search : undefined,
				partialFrameNumber: filters.searchType === SearchType.CHASSIS ? filters.search : undefined,
				partialTruckNumber: filters.searchType === SearchType.TRUCK ? filters.search : undefined,
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<AutoCompleteResult>) => {
					return response.data;
				}),
			);
	}

	fetchContainersStillOnSite = (filters: Filters): Observable<StatsStillOnSite> =>
		from(axios({
			...APIRoutes.containersStillOnSite(sessionQuery.currentSiteId),
			params: { containerNumber: filters.search || undefined },
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<StatsStillOnSite>) => {
					return response.data;
				}),
			);

	fetchLastContainersEvents = (filters: Filters): Observable<EventCount[]> =>
		from(axios({
			...APIRoutes.lastContainersEvents(sessionQuery.currentSiteId),
			params: {
				startDate: moment(filters.startDate).format("YYYY-MM-DD"),
				endDate: moment(filters.endDate).format("YYYY-MM-DD"),
				containerNumber: filters.search || undefined,
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<EventCount[]>) => {
					return response.data;
				}),
			);

	fetchTimeLapseOnSite = (filters: Filters, ts: string): Observable<TimeLapseOnSite[]> =>
		from(axios({
			...APIRoutes.timeLapsOnSite(sessionQuery.currentSiteId),
			params: {
				containerNumber: filters.search || undefined,
				ts,
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<TimeLapseOnSite[]>) => {
					return response.data;
				}),
			);

	fetchLocationOnSite = (filters: Filters): Observable<StatsLastLocation[]> =>
		from(axios({
			...APIRoutes.locationOnSite(sessionQuery.currentSiteId),
			params: { containerNumber: filters.search || undefined },
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<StatsLastLocation[]>) => {
					return response.data;
				}),
			);

	fetchTurnTimeEvents = (filters: Filters): Observable<TurnTimeEvent[]> =>
		from(axios({
			...APIRoutes.turnTimeEvents(sessionQuery.currentSiteId),
			params: {
				startDate: moment(filters.startDate).format("YYYY-MM-DD"),
				endDate: moment(filters.endDate).format("YYYY-MM-DD"),
				containerNumber: filters.search || undefined,
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<TurnTimeEvent[]>) => {
					return response.data;
				}),
			);

	fetchTruckTimeLapse = (filters: Filters, ts: string): Observable<TruckFrameTimeLapseOnSite[]> =>
		from(axios({
			...APIRoutes.truckTimeLapse(sessionQuery.currentSiteId),
			params: {
				truckNumber: filters.search || undefined,
				ts,
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<TruckFrameTimeLapseOnSite[]>) => {
					return response.data;
				}),
			);

	fetchChassisTimeLapse = (filters: Filters, ts: string): Observable<TruckFrameTimeLapseOnSite[]> =>
		from(axios({
			...APIRoutes.chassisTimeLapse(sessionQuery.currentSiteId),
			params: {
				frameNumber: filters.search || undefined,
				ts,
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<TruckFrameTimeLapseOnSite[]>) => {
					return response.data;
				}),
			);

	addSitePermission = (email: string, role: string): Observable<string | undefined> =>
		from(axios({
			...APIRoutes.addSitePermission(sessionQuery.currentSiteId),
			data: { email, role },
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_PERMISSION_ALREADY_EXISTS":
							throw new SnackError("warning.sitePermissionAlreadyExists", "warning");
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<SitePermission | undefined>) => {
						return response.data;
				}),
				map((permission?: SitePermission) => {
					if (permission) {
						this.store.addPermission(permission);
					} else {
						return "success.mailSent";
					}
				}),
			);

	removeSitePermission = (userId: string): Observable<AxiosResponse> =>
		from(axios({...APIRoutes.removeSitePermission(sessionQuery.currentSiteId, userId)}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "CANNOT_DELETE_ADMIN_OF_SITE":
							throw new SnackError("warning.cannotDeleteAdminOfSite", "warning");
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						case "SITE_PERMISSION_NOT_FOUND":
							throw new SnackError("errors.sitePermissionNotFound", "error");
						case "USER_NOT_FOUND":
							throw new SnackError("errors.userNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				tap(() => {
					this.store.removePermission(userId);
				}),
			);

	fetchContainersForEvent = (eventId: string, page: number): Observable<ContainersPaging> => {
		const filters = siteQuery.filters;

		return from(axios({
			...APIRoutes.containersForEvent(sessionQuery.currentSiteId, eventId),
			params: {
				startDate: moment(filters.startDate).format("YYYY-MM-DD"),
				endDate: moment(filters.endDate).format("YYYY-MM-DD"),
				containerNumber: filters.search || undefined,
				page,
				itemsPerPage: 15,
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						case "EVENT_TEMPLATE_NOT_FOUND":
							throw new SnackError("errors.eventTemplateNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<ContainersPaging>) => {
					return response.data;
				}),
			);
	}

	fetchContainersWithLocation = (page: number): Observable<ContainersPaging> =>
		from(axios({
			...APIRoutes.containersWithLocation(sessionQuery.currentSiteId),
			params: {
				containerNumber: siteQuery.filters.search || undefined,
				page,
				itemsPerPage: 15,
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<ContainersPaging>) => {
					return response.data;
				}),
			);

	fetchContainers = (page: number): Observable<ContainersPaging> =>
		from(axios({
			...APIRoutes.containers(sessionQuery.currentSiteId),
			params: {
				containerNumber: siteQuery.filters.search || undefined,
				page,
				itemsPerPage: 15
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<ContainersPaging>) => {
					return response.data;
				}),
			);

	fetchTrucks = (page: number): Observable<TrucksFramesPaging> =>
		from(axios({
			...APIRoutes.trucks(sessionQuery.currentSiteId),
			params: {
				truckNumber: siteQuery.filters.search || undefined,
				page,
				itemsPerPage: 15
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<TrucksFramesPaging>) => {
					return response.data;
				}),
			);

	fetchChassis = (page: number): Observable<TrucksFramesPaging> =>
		from(axios({
			...APIRoutes.chassis(sessionQuery.currentSiteId),
			params: {
				frameNumber: siteQuery.filters.search || undefined,
				page,
				itemsPerPage: 15
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<TrucksFramesPaging>) => {
					return response.data;
				}),
			);

	fetchContainerDetails = (container: ContainerDetailsParams): Observable<LightContainer> =>
		from(axios({...APIRoutes.containerDetails(container.containerId)}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "CONTAINER_NOT_FOUND":
							throw new SnackError("errors.containerNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<LightContainer>) => {
					return response.data;
				}),
			);

	fetchContainerEvents = (container: ContainerDetailsParams): Observable<Event[]> =>
		from(axios({
			...APIRoutes.containerEvents(sessionQuery.currentSiteId, container.containerId),
			params: { cycleId: container.cycleId || undefined },
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						case "CONTAINER_NOT_FOUND":
							throw new SnackError("errors.containerNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
				map((response: AxiosResponse<Event[]>) => {
					return response.data;
				}),
			);

	exportContainersForEvent = (eventId: string, filters: Filters): Observable<AxiosResponse> =>
		from(axios({
			...APIRoutes.exportContainersForEvent(sessionQuery.currentSiteId, eventId),
			params: {
				startDate: moment(filters.startDate).format("YYYY-MM-DD"),
				endDate: moment(filters.endDate).format("YYYY-MM-DD"),
			},
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						case "EVENT_TEMPLATE_NOT_FOUND":
							throw new SnackError("errors.eventTemplateNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
			);

	exportTrucks = (): Observable<AxiosResponse> =>
		from(axios({
			...APIRoutes.exportTrucks(sessionQuery.currentSiteId),
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
			);

	exportChassis = (): Observable<AxiosResponse> =>
		from(axios({
			...APIRoutes.exportChassis(sessionQuery.currentSiteId),
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
			);

	exportContainers = (): Observable<AxiosResponse> =>
		from(axios({
			...APIRoutes.exportContainers(sessionQuery.currentSiteId),
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
			);

	exportContainerEvents = (container: ContainerDetailsParams): Observable<AxiosResponse> =>
		from(axios({
			...APIRoutes.exportContainerEvents(sessionQuery.currentSiteId, container.containerId),
			params: { cycleId: container.cycleId || undefined },
		}))
			.pipe(
				catchError((err) => {
					switch (err.response?.data?.errorMessage) {
						case "SITE_NOT_FOUND":
							throw new SnackError("errors.siteNotFound", "error");
						case "CONTAINER_NOT_FOUND":
							throw new SnackError("errors.containerNotFound", "error");
						default:
							throw new SnackError("errors.serverError", "error");
					}
				}),
			);

	updateFilters = (filters: { startDate?: Date; endDate?: Date; searchType?: SearchType; search?: string }) => {
		this.store.updateFilters(filters);
	}
}

export const siteService = new SiteService(siteStore);
