import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#43A5FF',
            contrastText: '#fff',
        },
    },
});

export default theme;
