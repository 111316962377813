import React from 'react';
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';
import {
    AppBar,
    Avatar,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";

import {sessionQuery, sessionService} from "../../store/session";

import {SelectColor, SelectTextField} from "../../components/TextFields";
import Dashboard from "./Dashboard/Dashboard";
import Containers from "./Containers/Containers";
import Events from "./Events/Events";
import Account from "../../components/Account";

import style from "./styles/authed";

enum OpenModalState {
    NONE,
    ACCOUNT,
}

interface AuthedProps extends RouteComponentProps, WithStyles<typeof style> {}

interface AuthedState {
    company: string;
    site: string;
    openModalState: OpenModalState;

}

class Authed extends React.Component<AuthedProps, AuthedState> {
    constructor(props: AuthedProps) {
        super(props);

        this.state = {
            company: sessionQuery.currentCompanyId,
            site: sessionQuery.currentSiteId,
            openModalState: OpenModalState.NONE,
        };
    }

    componentDidMount() {
        sessionQuery.currentIds$.subscribe((res) => {
            this.setState({ ...res });
        })
    }

    handleOpenModalState = (openModalState: OpenModalState) => {
        this.setState({ openModalState });
    }

    renderModal = () => {
        const { openModalState } = this.state;

        switch (openModalState) {
            case OpenModalState.ACCOUNT:
                return (
                    <Account
                        handleClose={() => {
                            this.handleOpenModalState(OpenModalState.NONE);
                        }} />
                );
            default:
                return null;
        }
    }

    render() {
        const { classes, history } = this.props;

        const { company, site } = this.state;

        const user = sessionQuery.currentUser;

        return (
            <div className={classes.container}>
                <AppBar className={classes.appBar} position="fixed">
                    <img
                        className={classes.logo}
                        onClick={() => { history.push("/"); }}
                        src="/images/dashboard/logo_buyco_little.png"
                        alt="" />
                    <div className={classes.sitesContainer}>
                        <SelectTextField
                            selected={site}
                            handleChange={(event) => {
                                sessionService.setCurrentSite(event.target.value as string);
                            }}
                            data={sessionQuery.companySitesForSelection} />
                    </div>
                    <div className={classes.userContainer}>
                        <Avatar
                            className={classes.avatar}
                            onClick={() => {
                                this.handleOpenModalState(OpenModalState.ACCOUNT);
                            }}>
                            {user.firstName[0] + user.lastName[0]}
                        </Avatar>
                        <div>
                            <Typography className={classes.userName}>
                                {`${user.firstName} ${user.lastName}`}
                            </Typography>
                            <div className={classes.userCompany}>
                                <SelectTextField
                                    selected={company}
                                    selectColor={SelectColor.TRANSPARENT}
                                    handleChange={(event) => {
                                        sessionService.setCurrentCompany(event.target.value as string);
                                    }}
                                    data={sessionQuery.companiesForSelection} />
                            </div>
                        </div>
                    </div>
                </AppBar>
                <div className={classes.content}>
                    <Switch>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/events" component={Events} />
                        <Route exact path="/containers" component={Containers} />
                        <Redirect to="/" />
                    </Switch>
                </div>
                {this.renderModal()}
            </div>
        );
    }
}

export default withStyles(style)(
    withRouter(Authed)
);
