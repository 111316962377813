import React from "react";
import {
    Avatar,
    Box, Button,
    Card,
    CardHeader, ClickAwayListener,
    IconButton,
    MenuItem,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import {DraggableProvided, DraggableStateSnapshot} from "react-beautiful-dnd";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LockIcon from '@material-ui/icons/Lock';
import {WithTranslation, withTranslation} from "react-i18next";
import {withSnackbar, WithSnackbarProps} from "notistack";

import {eventsService, EventTemplate} from "../../store/events";

import Feature from "./Feature";
import EventNameCard from "./EventNameCard";
import ManageAttributions from "./ManageAttributions";

import style from "./styles/eventCard";
import {getEventTypeName} from "../../utils/events.utils";

const eventCardContainerStyle =
    (provided?: DraggableProvided, isDragging: boolean = false) =>
({
    alignSelf: 'flex-start',
    margin: '0 13px 0 0',
    ...(provided?.draggableProps.style || {}),
    display: 'inline',
    outline: 'none',
    opacity: isDragging ? 0.8 : 1,
});

enum OpenModalState {
    NONE,
    EVENT_OPTIONS,
    EDIT_EVENT,
    MANAGE_ATTRIBUTIONS,
}

interface EventCardProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    canBeDeleted: boolean;
    event: EventTemplate
    provided?: DraggableProvided;
    snapshot?: DraggableStateSnapshot;
}

interface EventCardState {
    openModalState: OpenModalState;
}

class EventCard extends React.Component<EventCardProps, EventCardState> {
    constructor(props: EventCardProps) {
        super(props);

        this.state = {
            openModalState: OpenModalState.NONE,
        };
    }

    handleOpenModalState = (openModalState: OpenModalState) => {
        this.setState({ openModalState });
    }

    renderEventOptions = () => {
        const { canBeDeleted, classes, enqueueSnackbar, event, t } = this.props;

        return (
            <ClickAwayListener
                onClickAway={() => {
                    this.handleOpenModalState(OpenModalState.NONE);
                }}>
                <div className={classes.eventOption}>
                    <Card className={classes.optionsMenu} elevation={4}>
                        <MenuItem
                            className={classes.optionsItem}
                            onClick={() => {
                                this.handleOpenModalState(OpenModalState.EDIT_EVENT);
                            }}>
                            {t("events.edit")}
                        </MenuItem>
                        {canBeDeleted && (
                            <MenuItem
                                component={Box}
                                borderTop={1}
                                className={classes.optionsItem}
                                onClick={() => {
                                    eventsService.deleteEvent(event.id).subscribe(
                                        () => {
                                            this.handleOpenModalState(OpenModalState.NONE);
                                        },
                                        (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                                    );
                                }}>
                                {t("events.delete")}
                            </MenuItem>
                        )}
                    </Card>
                </div>
            </ClickAwayListener>
        );
    }

    renderEditEvent = () => {
        const { classes, enqueueSnackbar, event, t } = this.props;

        const eventLabel = event.label.localeCompare(getEventTypeName(event.type), undefined, { sensitivity: 'accent' })
            ? event.label
            : undefined;

        return (
            <ClickAwayListener
                onClickAway={() => {
                    this.handleOpenModalState(OpenModalState.NONE);
                }}>
                <div className={classes.eventOption}>
                    <EventNameCard
                        title={t("events.editEventName")}
                        value={eventLabel}
                        handleCancelClick={() => {
                            this.handleOpenModalState(OpenModalState.NONE);
                        }}
                        handleOkClick={(eventName) => {
                            eventsService.changeEventName(event.id, eventName).subscribe(
                                () => {
                                    this.handleOpenModalState(OpenModalState.NONE);
                                },
                                (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                            );
                        }} />
                </div>
            </ClickAwayListener>
        );
    }

    renderModal = () => {
        const { event } = this.props;

        const { openModalState } = this.state;

        switch (openModalState) {
            case OpenModalState.EVENT_OPTIONS:
                return this.renderEventOptions();
            case OpenModalState.EDIT_EVENT:
                return this.renderEditEvent();
            case OpenModalState.MANAGE_ATTRIBUTIONS:
                return (
                    <ManageAttributions
                        event={event}
                        attributions={event.attributions}
                        handleClose={() => {
                            this.handleOpenModalState(OpenModalState.NONE);
                        }} />
                );
            default:
                return null
        }
    }

    render() {
        const { canBeDeleted, classes, event, provided, snapshot, t } = this.props;

        const eventTypeName = getEventTypeName(event.type);

        return (
            <div
                ref={provided ? provided.innerRef : undefined}
                {...(provided?.draggableProps || {})}
                {...(provided?.dragHandleProps || {})}
                style={eventCardContainerStyle(provided, snapshot?.isDragging)}>
                <div className={classes.container}>
                    <Card classes={{ root: classes.card }}>
                        <CardHeader
                            className={classes.header}
                            titleTypographyProps={{ classes: { root: classes.titleContainer } }}
                            title={(
                                <>
                                    {!canBeDeleted && <LockIcon className={classes.lockIcon} />}
                                    <Typography className={classes.title}>
                                        {event.label !== ""
                                            ? `${event.label.toUpperCase()} ${eventTypeName ? `(${eventTypeName.toLowerCase()})` : ""}`
                                            : eventTypeName?.toUpperCase() || ""
                                        }
                                    </Typography>
                                </>
                            )} />
                        <div className={classes.action}>
                            <IconButton
                                onClick={() => {
                                    this.handleOpenModalState(OpenModalState.EVENT_OPTIONS);
                                }}>
                                <MoreVertIcon />
                            </IconButton>
                        </div>
                        <div className={classes.features}>
                            <Typography className={classes.contentTitle}>
                                {t("events.features")}
                            </Typography>
                            <Feature event={event} isAddFeature />
                            {event.features.map((feature) => (
                                <Feature key={feature.id} event={event} feature={feature} />
                            ))}
                        </div>
                        <div className={classes.attributions}>
                            <Typography className={classes.contentTitle}>
                                {t("events.attribution")}
                            </Typography>
                            {event.attributions.length
                                ? (
                                    <Button
                                        className={classes.eventButtonOperators}
                                        onClick={() => {
                                            this.handleOpenModalState(OpenModalState.MANAGE_ATTRIBUTIONS);
                                        }}>
                                        {t("events.operators")}
                                        <Avatar className={classes.eventBadge}>{event.attributions.length}</Avatar>
                                    </Button>
                                )
                                : (
                                    <Button
                                        className={classes.eventButtonAdd}
                                        onClick={() => {
                                            this.handleOpenModalState(OpenModalState.MANAGE_ATTRIBUTIONS);
                                        }}>
                                        <img className={classes.eventIcon} src="/images/events/icon_add_feature.svg" alt="" />
                                        {t("events.addPeople")}
                                    </Button>
                                )
                            }
                        </div>
                    </Card>
                    {this.renderModal()}
                </div>
            </div>
        );
    }
}

export default withStyles(style)(
    withTranslation()(withSnackbar(EventCard))
);
