import React from "react";
import OlMap from "ol/Map";
import OlView from "ol/View";
import * as Layer from "ol/layer";
import * as Source from "ol/source";
import {Coordinate} from "ol/coordinate";
import {withStyles, WithStyles} from "@material-ui/core";
import VectorLayer from "ol/layer/Vector";

import 'ol/ol.css';
import style from "./styles/map";

interface MapProps extends WithStyles<typeof style> {
    center: Coordinate;
    clusters?: VectorLayer;
    onClusterClick?: (data: any) => void;
}

interface MapState {
    center?: Coordinate;
    zoom?: number;
}

class Map extends React.Component<MapProps, MapState> {
    private olMap: OlMap;

    constructor(props: MapProps) {
        super(props);

        this.state = {
            center: props.center,
            zoom: 10,
        };

        let layers: any[] = [
            new Layer.Tile({
                source: new Source.BingMaps({
                    key: process.env.REACT_APP_MAP_API_KEY!,
                    imagerySet: "CanvasGray",
                }),
            })
        ];

        if (props.clusters) layers.push(props.clusters);

        this.olMap = new OlMap({
            target: undefined,
            layers,
            view: new OlView({
                center: this.state.center,
                zoom: this.state.zoom,
                enableRotation: false,
            }),
        });
    }

    componentDidMount() {
        const { onClusterClick } = this.props;

        this.olMap.setTarget("map");

        setTimeout(() => { this.olMap.updateSize(); }, 1000);

        if (onClusterClick) {
            this.olMap.on("click", (evt) => {
                this.olMap.forEachFeatureAtPixel(evt.pixel, (cluster) => {
                    let markers = cluster.get("features");

                    onClusterClick(markers.map((m: any) => m.getProperties().attributes));
                });
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<MapProps>, prevState: Readonly<MapState>, snapshot?: any) {
        if (prevProps.clusters !== this.props.clusters) {
            this.olMap.getLayers().forEach((layer, index) => {
                if (index !== 0) {
                    this.olMap.removeLayer(layer);
                }
            });

            if (this.props.clusters) this.olMap.addLayer(this.props.clusters);
        }

        if (prevProps.center !== this.props.center) {
            this.olMap.getView().setCenter(this.props.center);
            this.olMap.updateSize();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div id="map" className={classes.map} />
        );
    }
}

export default withStyles(style)(Map);
