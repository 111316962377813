import { createStyles } from "@material-ui/core";

const lastContainersEvents = () => createStyles({
    container: {
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(400px - 19px - 19px)',
        padding: '19px 28px',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 20,
    },
    grid: {
        flex: 1,
        marginBottom: 20,
    },
    eventButton: {
        borderRadius: 10,
        height: 80,
        textTransform: 'none',
    },
    containerNumber: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: 20,
        fontWeight: 700,
    },
    eventType: {
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
    },
    dateRow: {
        display: 'flex',
        "& > :first-child": {
            marginRight: 15,
        },
    },
    dateContainer: {
        flex: 1,
    },
});

export default lastContainersEvents;
