import React, {useState} from "react";
import {CardContent, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";

import ActionModal from "../ActionModal";

import style from "./styles/eventNameCard";
import {AuthTextField} from "../TextFields";

interface EventNameCardProps extends WithStyles<typeof style>, WithTranslation {
    handleCancelClick: () => void;
    handleOkClick: (eventName?: string) => void;
    title: string,
    value?: string,
}

const EventNameCard = (props: EventNameCardProps) => {
    const { classes, handleCancelClick, handleOkClick, t, title, value } = props;

    const [eventName, setEventName] = useState<string>(value || "");

    return (
        <div>
            <ActionModal
                title={title}
                handleOk={() => {
                    handleOkClick(eventName);
                }}
                handleCancel={handleCancelClick}>
                <CardContent className={classes.content}>
                    <AuthTextField
                        label={t("input.name")}
                        value={eventName}
                        handleChange={(event) => {
                            setEventName(event.target.value);
                        }} />
                </CardContent>
            </ActionModal>
        </div>
    );
}

export default withStyles(style)(
    withTranslation()(EventNameCard)
);
