import { createStyles } from "@material-ui/core";

const turnTimeOfEachEvent = () => createStyles({
    container: {
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(400px - 20px - 20px)',
        padding: '20px 0px',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
        margin: '0 28px 20px',
    },
    dateRow: {
        display: 'flex',
        marginTop: 20,
        padding: '0 28px',
        "& > :first-child": {
            marginRight: 15,
        },
    },
    dateContainer: {
        flex: 1,
    },
});

export default turnTimeOfEachEvent;
