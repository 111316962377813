import React, {useEffect, useState} from "react";
import {Card, CardActionArea, Typography, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {Bar} from "react-chartjs-2";
import {withSnackbar, WithSnackbarProps} from "notistack";
import {switchMap} from "rxjs/operators";

import {sessionQuery} from "../../store/session";
import {Filters, siteService, TruckFrameTimeLapseOnSite} from "../../store/site";

import style from "./styles/chassisTimeLapseSinceArrival";
interface ChassisTimeLapseSinceArrivalProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    filters: Filters;
    onModuleClick: () => void;
}

const barOptions = {
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            offset: true,
            stacked: true,
            gridLines: {
                drawTicks: false,
                drawOnChartArea: false,
            },
            ticks: {
                maxRotation: 0,
                maxTicksLimit: 10,
                padding: 10,
            },
        }],
        yAxes: [{
            type: 'linear',
            ticks: {
                beginAtZero: true,
                suggestedMax: 10,
            },
            display: false,
        }],
    },
};

const ChassisTimeLapseSinceArrival = (props: ChassisTimeLapseSinceArrivalProps) => {
    const { classes, enqueueSnackbar, filters, onModuleClick, t } = props;


    const [counts, setCounts] = useState<TruckFrameTimeLapseOnSite[]>([]);

    useEffect(() => {
         const timeLapse = sessionQuery.currentSite$.pipe(
            switchMap(() => {
                const ts = Math.floor((new Date()).getTime() / 1000).toString();
                return siteService.fetchChassisTimeLapse(filters, ts);
            }),
        ).subscribe(
            (c) => {
                setCounts(c);
            },
            (err) => { enqueueSnackbar(t(err.key), { variant: "error"}); },
        );

        return () => timeLapse.unsubscribe();
    }, [enqueueSnackbar, filters, t]);

    let max = counts.length ? Math.max.apply(Math, counts.map((c) => c.hours)) : 20;
    if (max < 20) max = 20;
    max = max === 0 ? 10 : Math.ceil(max / 10) * 10;
    const labels = Array.from({length: max}, (i,index) => index);

    const mappedCounts = counts.reduce((arr, count) => {
        arr[count.hours] += count.count;

        return arr;
    }, labels.map(() => 0));

    return (
        <Card elevation={3} className={classes.container} onClick={onModuleClick}>
            <CardActionArea className={classes.actionArea}>
                <Typography className={classes.title}>
                    {t('modules.chassisTimeLapseSinceArrival')}
                </Typography>
                <Bar
                    options={{
                        ...barOptions,
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem: any[]) {
                                    return t("modules.hours", { hours: tooltipItem[0]["index"]});
                                },
                            },
                        },
                    }}
                    height={140}
                    data={{
                        labels,
                        datasets: [
                            {
                                label: t("modules.containers"),
                                backgroundColor: '#108DFF',
                                data: mappedCounts,
                            },
                        ]
                    }} />
                    <Typography className={classes.xAxisTitle}>
                        {t("modules.turnTimeHours")}
                    </Typography>
            </CardActionArea>
        </Card>
    )
}

export default withStyles(style)(
    withTranslation()(withSnackbar(ChassisTimeLapseSinceArrival))
);
