import React, {createRef} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton, withStyles, WithStyles} from "@material-ui/core";
import {Close} from "@material-ui/icons";

import style from "./styles/bigPicture";

interface BigPictureProps extends WithStyles<typeof style> {
	image: string;
	handleClose: () => void;
}

const BigPicture = (props: BigPictureProps) => {
	const { classes, handleClose, image } = props;

	const dialog = createRef();

	return (
		<Dialog ref={dialog} open={true} onClose={handleClose} maxWidth="md" classes={{ paper: classes.container }}>
			<DialogTitle className={classes.title}>
				<IconButton className={classes.closeIcon} onClick={handleClose}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<img className={classes.image} alt="" src={image} />
			</DialogContent>
		</Dialog>
	);
}

export default withStyles(style)(BigPicture);
