import { createStyles } from "@material-ui/core";

const dashboard = () => createStyles({
    container: {
        flex: 1,
    },
    gridContent: {
        padding: '40px 50px',
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    searchContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    dateContainer: {
        marginRight: 13,
    },
    selectContainer: {
        alignItems: 'center',
        backgroundColor: '#E7EAF3',
        borderRadius: 10,
        display: 'flex',
    },
    divider: {
        backgroundColor: '#979797',
        height: 34,
        opacity: 0.4,
        width: 1,
    },
    exportDataButton: {
        borderRadius: 10,
        height: 50,
        marginRight: 13,
        width: 50,
    },
    editButton: {
        borderRadius: 10,
        height: 50,
        width: 50,
    },
    scrollTop: {
        backgroundColor: '#3AB4FB',
        color: 'white',
    },
});

export default dashboard;
