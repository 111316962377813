import Keycloak, {KeycloakInstance} from 'keycloak-js';

const keycloakConfig = {
	realm: process.env.REACT_APP_SSO_REALM!,
	url: process.env.REACT_APP_SSO_HOST!,
	clientId: process.env.REACT_APP_SSO_CLIENT_ID!,
};

const keycloak: KeycloakInstance = Keycloak(keycloakConfig);

export default keycloak;
