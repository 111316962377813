import {createStyles} from "@material-ui/core";

const containerEvents = () => createStyles({
    card: {
        borderRadius: 10,
        flex: 1,
        padding: '0 30px 0 30px',
    },
    cardTitle: {
        alignItems: 'center',
        color: '#1E2F4F',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 700,
        justifyContent: 'space-between',
    },
    timelineOpposite: {
        flex: 'inherit',
        paddingTop: 0,
    },
    date: {
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 500,
        textTransform: 'uppercase',
    },
    hour: {
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 500,
    },
    dot: {
        backgroundColor: '#108DFF',
        color: '#108DFF',
        height: 20,
        margin: '0 30px',
        padding: 0,
        width: 20,
    },
    timelineContent: {
        paddingTop: 0,
    },
    eventName: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 500,
    },
    eventOperator: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 10,
    },
    paper: {
        backgroundColor: '#F3F5F9',
        borderRadius: 5,
        marginBottom: 15,
        padding: 10,
        "& > :nth-child(n):not(:last-child)": {
            marginBottom: 10,
        },
    },
    showFeaturesButton: {
        color: '#108DFF',
        textTransform: 'none',
        whiteSpace: 'nowrap',
    },
    showFeaturesEndIcon: {
        marginBottom: 1,
    },
    featureTitle: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 500,
    },
    featureValue: {
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
    },
    featurePictures: {
        marginTop: 5,
        "& > :nth-child(n):not(:nth-child(9n))": {
            marginRight: 7,
        },
        "& > :nth-child(n + 10)": {
            marginTop: 7,
        },
    },
    featurePicture: {
        borderRadius: 5,
        cursor: 'pointer',
        height: 55,
        objectFit: 'cover',
        width: 66,
    }
});

export default containerEvents;
