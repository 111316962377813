import React, {useEffect, useState} from "react";
import {Card, CardActionArea, Typography, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {Bar} from "react-chartjs-2";
import {withSnackbar, WithSnackbarProps} from "notistack";

import {Filters, siteService, TimeLapseOnSite} from "../../store/site";

import style from "./styles/timeLapseSinceArrivalOnSite";
import {sessionQuery} from "../../store/session";
import {switchMap} from "rxjs/operators";

interface TimeLapseSinceArrivalOnSiteProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    filters: Filters;
    onModuleClick: () => void;
}

const barOptions = {
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            offset: true,
            stacked: true,
            gridLines: {
                drawTicks: false,
                drawOnChartArea: false,
            },
            ticks: {
                maxRotation: 0,
                maxTicksLimit: 10,
                padding: 10,
            },
        }],
        yAxes: [{
            type: 'linear',
            ticks: {
                beginAtZero: true,
                suggestedMax: 10,
            },
            display: false,
        }],
    },
};

const TimeLapseSinceArrivalOnSite = (props: TimeLapseSinceArrivalOnSiteProps) => {
    const { classes, enqueueSnackbar, filters, onModuleClick, t } = props;

    const [counts, setCounts] = useState<TimeLapseOnSite[]>([]);

    useEffect(() => {
        const timeLapse = sessionQuery.currentSite$.pipe(
            switchMap(() => {
                const ts = Math.floor((new Date()).getTime() / 1000).toString();
                return siteService.fetchTimeLapseOnSite(filters, ts);
            }),
        ).subscribe(
            (c) => {
                setCounts(c);
            },
            (err) => { enqueueSnackbar(t(err.key), { variant: "error"}); },
        );

        return () => timeLapse.unsubscribe();
    }, [enqueueSnackbar, filters, t]);

    let max = counts.length ? Math.max.apply(Math, counts.map((c) => c.days)) : 30;
    if (max < 30) max = 30;
    max = max === 0 ? 10 : Math.ceil(max / 10) * 10;
    const labels = Array.from({length: max}, (i,index) => index);

    const mappedSealedContainers = counts.reduce((arr, count) => {
        arr[count.days] += count.sealedCount;

        return arr;
    }, labels.map(() => 0));


    const mappedUnsealedContainers = counts.reduce((arr, count) => {
        arr[count.days] += count.unsealedCount;

        return arr;
    }, labels.map(() => 0));

    return (
        <Card elevation={3} className={classes.container} onClick={onModuleClick}>
            <CardActionArea className={classes.actionArea}>
                <Typography className={classes.title}>
                    {t('modules.timeLapseSinceArrivalOnSite')}
                </Typography>
                <Bar
                    options={{
                        ...barOptions,
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem: any[]) {
                                    return t("modules.days", { days: tooltipItem[0]["index"]});
                                },
                            },
                        },
                    }}
                    height={140}
                    data={{
                        labels,
                        datasets: [
                            {
                                label: t("modules.sealed"),
                                stack: t("modules.sealed"),
                                backgroundColor: '#60B690',
                                data: mappedSealedContainers,
                            },
                            {
                                label: t("modules.notSealed"),
                                stack: t("modules.sealed"),
                                backgroundColor: '#1C9195',
                                data: mappedUnsealedContainers,
                            },
                        ]
                    }} />
                <Typography className={classes.xAxisTitle}>
                    {t("modules.downTime")}
                </Typography>
            </CardActionArea>
        </Card>
    )
}

export default withStyles(style)(
    withTranslation()(withSnackbar(TimeLapseSinceArrivalOnSite))
);
