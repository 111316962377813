import React, {FormEvent} from "react";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader, Divider,
    IconButton,
    List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText,
    Modal, Typography,
    WithStyles,
    withStyles
} from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import {User} from "../store/session";

import {AuthTextField} from "./TextFields";
import {ClassicButton} from "./Buttons";
import {Role, SitePermission} from "../store/site";

import style from "./styles/manageUsers";
import {getRoleName} from "../utils/roles.utils";

enum OpenModalState {
    NONE,
    ADD_USER
}

interface ManageUsersProps extends WithStyles<typeof style>, WithTranslation {
    formInputs?: React.ReactNode[];
    handleAddUser: (event: FormEvent<HTMLElement>, onSuccess: () => void) => void;
    handleClose: () => void;
    handleRemoveUser: (userId: string) => void;
    title: string;
    isSitePermissions?: boolean;
    users?: User[];
    permissions?: SitePermission[];
}

interface ManageUsersState {
    search: string;
    openModalState: OpenModalState;
}

class ManageUsers extends React.Component<ManageUsersProps, ManageUsersState> {
    constructor(props: ManageUsersProps) {
        super(props);

        this.state = {
            search: '',
            openModalState: !props.users || props.users.length ? OpenModalState.NONE : OpenModalState.ADD_USER,
        };
    }

    handleOpenModalState = (openModalState: OpenModalState) => {
        this.setState({ openModalState, search: '' });
    }

    renderAddUser = () => {
        const { classes, formInputs, handleAddUser, handleClose, t, title, users } = this.props;

        return (
            <Modal open onClose={handleClose}>
                <Card className={classes.paper}>
                    <CardHeader
                        className={classes.header}
                        titleTypographyProps={{ classes: { root: classes.title } }}
                        action={(
                            <IconButton
                                onClick={() => {
                                    if (!users || users.length) {
                                        this.handleOpenModalState(OpenModalState.NONE);
                                    } else {
                                        handleClose();
                                    }
                                }}
                                className={classes.close}>
                                <CloseIcon />
                            </IconButton>
                        )}
                        title={title} />
                    <CardContent
                        className={classes.addUserFormContainer}
                        component="form"
                        onSubmit={(event: FormEvent<HTMLElement>) => {
                            handleAddUser(event, () => {
                               this.handleOpenModalState(OpenModalState.NONE);
                            });
                        }}>
                        <Typography className={classes.addUserTitle}>
                            {t("events.addPeople")}
                        </Typography>
                        <AuthTextField
                            autoComplete="email"
                            label={t("input.email")}
                            icon={(
                                <img alt="" src="/images/permissions/icon_mail.svg" className={classes.emailIcon} />
                            )}
                            name="email"
                            required />
                        {formInputs && formInputs.map((input) => (
                            <div className={classes.inputContainer}>
                                {input}
                            </div>
                        ))}
                        <div className={classes.addUserButton}>
                            <ClassicButton type="submit" title={t('permissions.send')} />
                        </div>
                    </CardContent>
                </Card>
            </Modal>
        );
    }

    renderModal = () => {
        const { openModalState } = this.state;

        switch (openModalState) {
            case OpenModalState.ADD_USER:
                return this.renderAddUser();
            default:
                return null;
        }
    }

    render() {
        const {
            classes, handleClose, handleRemoveUser, isSitePermissions, permissions, t, title
        } = this.props;
        let { users = [] } = this.props;

        const { openModalState, search } = this.state;

        if (isSitePermissions) {
            users = (permissions || []).map(({user}) => { return user; });
        }

        const filteredUsers = users.filter((user: User) => {
            const username = `${user.firstName} ${user.lastName}`;

            return username.toLowerCase().includes(search.toLowerCase());
        });

        return (
          <>
              <Modal open={openModalState === OpenModalState.NONE} onClose={handleClose}>
                  <Card className={classes.paper}>
                      <CardHeader
                        className={classes.header}
                        titleTypographyProps={{ classes: { root: classes.title } }}
                        action={(
                          <IconButton onClick={handleClose} className={classes.close}>
                              <CloseIcon />
                          </IconButton>
                        )}
                        title={title} />
                      <CardContent className={classes.content}>
                          <div className={classes.contentHeader}>
                              <AuthTextField
                                placeholder={t("permissions.search")}
                                value={search}
                                icon={<SearchIcon />}
                                handleChange={(evt) => {
                                    this.setState({ search: evt.target.value });
                                }} />
                              <Button
                                className={classes.addButton}
                                onClick={() => {
                                    this.handleOpenModalState(OpenModalState.ADD_USER);
                                }}
                                startIcon={(
                                  <img alt="" src="/images/permissions/icon_add_people.svg" />
                                )}>
                                  {t("permissions.add")}
                              </Button>
                          </div>
                          <List className={classes.users}>
                              {filteredUsers.map((user) => {
                                  const role = (permissions || [])
                                    .find((p) => p.user.id === user.id)?.role || Role.VIEWER;

                                  return (
                                    <div key={user.id}>
                                        <ListItem className={classes.user}>
                                            <ListItemAvatar>
                                                <Avatar className={classes.avatar}>
                                                    {user.firstName[0] + user.lastName[0]}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                              classes={{ primary: classes.userName }}
                                              primary={`${user.firstName} ${user.lastName}`}
                                              secondary={`${user.email}${(isSitePermissions ? ` - ${t(getRoleName(role))}` : "")}`} />
                                            {role !== Role.SITE_OWNER && (
                                              <ListItemSecondaryAction>
                                                  <IconButton
                                                    onClick={() => {
                                                        handleRemoveUser(user.id);
                                                    }}>
                                                      <img alt="" src="/images/permissions/icon_delete.svg" />
                                                  </IconButton>
                                              </ListItemSecondaryAction>
                                            )}
                                        </ListItem>
                                        <Divider />
                                    </div>
                                  );
                              })}
                          </List>
                      </CardContent>
                  </Card>
              </Modal>
              {this.renderModal()}
          </>
        );
    }
}

export default withStyles(style)(
    withTranslation()(ManageUsers)
);
