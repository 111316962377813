import React, {useEffect, useState} from "react";
import {Button, Card, Grid, Typography, WithStyles} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";
import {withSnackbar, WithSnackbarProps} from "notistack";
import {switchMap} from "rxjs/operators";

import {EventCount, Filters, siteService} from "../../store/site";
import {sessionQuery} from "../../store/session";

import {getEventTypeName, getEventTypeStyle} from "../../utils/events.utils";

import {DateTextField} from "../TextFields";

import style from './styles/lastContainersEvents';

interface LastContainersEventsProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    filters: Filters;
    onEventClick: (eventCount: EventCount) => void;
}

const LastContainersEvents = (props: LastContainersEventsProps) => {
    const { classes, enqueueSnackbar, filters, onEventClick, t } = props;

    const [events, setEvents] = useState<EventCount[]>([]);

    useEffect(() => {
        const lastContainersEvents = sessionQuery.currentSite$.pipe(
            switchMap(() => {
                return siteService.fetchLastContainersEvents(filters);
            })
        ).subscribe(
            (e) => {
                setEvents(e);
            },
            (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
        );

        return () => {
            lastContainersEvents.unsubscribe();
        };
    }, [enqueueSnackbar, filters, t]);


    return (
        <Card elevation={3} className={classes.container}>
            <Typography className={classes.title}>
                {t('modules.lastContainersEvents')}
            </Typography>
            <Grid container spacing={2} className={classes.grid} alignContent="flex-start">
                {events.map((event, index) => (
                    <Grid key={`${event.eventTemplateName} - ${index} - ${event.count}`} item xs={4}>
                        <Button
                            className={classes.eventButton}
                            onClick={() => {
                                onEventClick(event);
                            }}
                            style={getEventTypeStyle(event.eventTemplateType)}
                            fullWidth>
                            <div>
                                <Typography className={classes.containerNumber}>
                                    {event.count}
                                </Typography>
                                <Typography className={classes.eventType}>
                                    {event.eventTemplateName || getEventTypeName(event.eventTemplateType)}
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                ))}
            </Grid>
            <div className={classes.dateRow}>
                <div className={classes.dateContainer}>
                    <DateTextField
                        date={filters.startDate}
                        fullWidth
                        handleChange={(date) => {
                            if (date) {
                                const endDate = date.isAfter(filters.endDate) ? date.toDate() : filters.endDate;
                                siteService.updateFilters({ startDate: date.toDate() || new Date(), endDate });
                            }
                        }}
                        label={t("input.dateFrom")} />
                </div>
                <div className={classes.dateContainer}>
                    <DateTextField
                        date={filters.endDate}
                        fullWidth
                        handleChange={(date) => {
                            if (date) {
                                const startDate = date.isBefore(filters.startDate) ? date.toDate() : filters.startDate
                                siteService.updateFilters({ endDate: date.toDate() || new Date(), startDate });
                            }
                        }}
                        label={t("input.dateTo")} />
                </div>
            </div>
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(withSnackbar(LastContainersEvents))
);
