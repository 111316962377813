import React from "react";
import {
    DragDropContext,
    Draggable,
    DraggableProvided, DraggableStateSnapshot,
    Droppable,
} from "react-beautiful-dnd";
import {WithStyles, withStyles} from "@material-ui/core";

import {ModuleType} from "../../store/session";
import {onDragEnd, useDraggableInPortal} from "../../utils/dnd.utils";

import style from "./styles/moduleOrganization";
import {getModulesRows, Module} from "../../utils/modules.utils";

const moduleContainerStyle = (provided?: DraggableProvided, isDragging?: boolean) => ({
    ...provided?.draggableProps.style || {},
    marginRight: 19,
    outline: 'none',
    opacity: isDragging ? 0.8 : 1,
});

interface OrganizationRowProps extends WithStyles<typeof style> {
    modules: Module[];
    rowNumber: number;
}

const OrganizationRow = withStyles(style)((props: OrganizationRowProps) => {
    const { classes, modules, rowNumber } = props;

    const renderDraggable = useDraggableInPortal();

    const selectedModules = modules.filter((m) => m.isSelected);
    const unselectedModules = modules.filter((m) => !m.isSelected);

    return (
        <div className={classes.row}>
            <Droppable droppableId={`droppable-${rowNumber}`} direction="horizontal">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={classes.droppable}>
                        {selectedModules.map((m) => (
                            <Draggable
                                key={m.type}
                                isDragDisabled={!m.isSelected}
                                draggableId={m.type.toString()}
                                index={m.position}>
                                {renderDraggable((provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={moduleContainerStyle(provided, snapshot.isDragging)}>
                                        {m.render()}
                                    </div>
                                ))}
                            </Draggable>
                        ))}
                        {selectedModules.length === 1 && provided.placeholder}
                    </div>
                )}
            </Droppable>
            <div className={classes.droppable}>
                {unselectedModules.map((m) => (
                    <div key={m.type} style={moduleContainerStyle()}>
                        {m.render()}
                    </div>
                ))}
            </div>
        </div>
    );
});

interface ModuleOrganizationProps extends WithStyles<typeof style> {
    modules: Module[];
    updateOrder: (modules: ModuleType[]) => void;
}

const ModuleOrganization = (props: ModuleOrganizationProps) => {
    const { modules, updateOrder } = props;

    const rows = getModulesRows(modules);

    return (
        <DragDropContext
            onDragEnd={(result) => {
                onDragEnd(result, modules.map((m) => m.type), updateOrder)
            }}>
            {rows.map((modulesRow, index) => (
                <OrganizationRow key={`droppable-${index}`} modules={modulesRow} rowNumber={index} />
            ))}
        </DragDropContext>
    );
};

export default withStyles(style)(ModuleOrganization);
