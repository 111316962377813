import {createStyles} from "@material-ui/core";

const authLayout = () => createStyles({
    container: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(/images/auth/img_login.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
});

export default authLayout;
