import React, {FormEvent} from "react";
import {withTranslation, WithTranslation} from "react-i18next";

import {eventsService, EventTemplate} from "../../store/events";
import {User} from "../../store/session";

import ManageUsers from "../ManageUsers";
import {withSnackbar, WithSnackbarProps} from "notistack";

interface ManageAttributionsProps extends WithTranslation, WithSnackbarProps {
    attributions: User[];
    event: EventTemplate;
    handleClose: () => void;
}

const ManageAttributions = (props: ManageAttributionsProps) => {
    const { attributions, enqueueSnackbar, event, handleClose, t } = props;

    return (
        <ManageUsers
            handleAddUser={(evt: FormEvent<HTMLElement>, onSuccess: () => void) => {
                evt.preventDefault();

                const email = (evt.target as any)["email"].value;

                eventsService.addAttribution(event.id, email).subscribe(
                    (successKey) => {
                        if (successKey) enqueueSnackbar(t(successKey, { email }), { variant: "success" });
                        onSuccess();
                    },
                    (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                );
            }}
            handleClose={handleClose}
            handleRemoveUser={(userId) => {
                eventsService.removeAttribution(event.id, userId).subscribe(
                    () => {},
                    (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                );
            }}
            title={t("permissions.eventAttribution")}
            users={attributions} />
    );
}

export default withTranslation()(
  withSnackbar(ManageAttributions)
);
