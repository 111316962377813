import {createStyles} from "@material-ui/core";

const map = () => createStyles({
    map: {
        height: '100%',
        width: '100%',
        "& .ol-zoom": {
            bottom: 8,
            left: 'unset',
            right: 8,
            top: 'unset',
        },
        "& .ol-zoom-in": {
            backgroundColor: '#43A5FF',
            cursor: 'pointer',
        },
        "& .ol-zoom-out": {
            backgroundColor: '#43A5FF',
            cursor: 'pointer',
        },
        "& .ol-attribution": {
            display: 'inline',
            left: 0,
            right: 'unset !important',
            textAlign: 'left',
        },
        "& .ol-attribution.ol-uncollapsible": {
            borderRadius: '0 4px 0',
        },
    },
});

export default map;
