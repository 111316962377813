import translationEN from './en/translation.json';

export enum Language {
    EN = "en",
}

export const translations = {
    en: {
        translation: translationEN,
    },
};
