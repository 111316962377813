import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import {MuiThemeProvider} from "@material-ui/core";
import {SnackbarProvider} from "notistack";

import theme from "./utils/theme.utils";

import Routes from "./scenes";

class App extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={window.navigator.language}>
                    <SnackbarProvider
                        style={{ borderRadius: 25, minWidth: 0 }}
                        maxSnack={3}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                        <Router>
                            <Routes />
                        </Router>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

export default App;
