import {Role} from "../store/site";

export const getRoleName = (role: Role): string => {
		switch (role) {
				case Role.SITE_OWNER:
						return "roles.siteOwner";
				case Role.ADMIN:
						return "roles.admin";
				case Role.VIEWER:
						return "roles.viewer";
				default:
						return "";
		}
}
