import React from "react";
import {CircularProgress, WithStyles, withStyles} from "@material-ui/core";

import AuthLayout from "../../components/AuthLayout";

import style from './styles/launchingScreen';

interface LaunchingScreenProps extends WithStyles<typeof style> {}

class LaunchingScreen extends React.Component<LaunchingScreenProps> {
    render() {
        const { classes } = this.props;

        return (
            <AuthLayout>
                <div className={classes.launchingScreen}>
                    <img src="/images/auth/logo_buyco_big.jpg" className={classes.logo} alt="" />
                    <CircularProgress className={classes.loader} size={80} />
                </div>
            </AuthLayout>
        );
    }
}

export default withStyles(style)(LaunchingScreen);
