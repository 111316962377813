import {createStyles} from "@material-ui/core";

const account = () => createStyles({
    paper: {
        alignItems: 'center',
        backgroundColor: 'white',
        border: 'none',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        left: '50%',
        outline: 'none',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
    },
    header: {
        padding: '27px 21px 0 33px',
        marginBottom: 21,
        width: 'calc(100% - 21px - 33px)',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
    },
    close: {
        color: '#1E2F4F',
        margin: 0,
    },
    avatar: {
        backgroundColor: '#CDD1DD',
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 25,
        fontWeight: 400,
        height: 100,
        marginBottom: 14,
        width: 100,
    },
    userName: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 700,
        marginBottom: 7,
    },
    userRole: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 25,
    },
    logOut: {
        color: '#43A5FF',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 23,
        textTransform: 'none',
    },
});

export default account;
