import {UserSite} from "../site/site.model";

/* ------------------DASHBOARD MODULES------------------ */
export enum ModuleType {
    CONTAINERS_STILL_ON_SITE = "module-1",
    LOCATION_ON_SITE = "module-2",
    TIME_LAPSE_SINCE_ARRIVAL_ON_SITE = "module-3",
    LAST_CONTAINERS_EVENTS = "module-4",
    TURN_TIME_OF_EACH_EVENT = "module-5",
    TRUCK_TIME_LAPSE_SINCE_ARRIVAL = "module-6",
    CHASSIS_TIME_LAPSE_SINCE_ARRIVAL = "module-7",
}

export const moduleTypes = [
    ModuleType.CONTAINERS_STILL_ON_SITE,
    ModuleType.LOCATION_ON_SITE,
    ModuleType.LAST_CONTAINERS_EVENTS,
    ModuleType.TIME_LAPSE_SINCE_ARRIVAL_ON_SITE,
    ModuleType.TURN_TIME_OF_EACH_EVENT,
    ModuleType.TRUCK_TIME_LAPSE_SINCE_ARRIVAL,
    ModuleType.CHASSIS_TIME_LAPSE_SINCE_ARRIVAL
];

export interface ModulesOrganizations {
    [key: string]: ModuleType[];
}
/* ----------------------------------------------------- */

export interface UserOrganization {
    id: string;
    name: string;
    sites: UserSite[];
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface CurrentUser extends User{
    email: string;
    organizations: UserOrganization[];
}

export interface UserConfiguration {
    currentCompanyId?: string;
    currentSiteId?: string;
    modulesOrganizations: ModulesOrganizations;
}

export interface Session {
    user?: CurrentUser;
    configuration: UserConfiguration;
}
