import React from "react";
import {Grid, Paper, WithStyles, withStyles} from "@material-ui/core";

import style from "./styles/authLayout";

interface AuthLayoutProps extends WithStyles<typeof style> {
    children: React.ReactNode;
}

const AuthLayout = (props: AuthLayoutProps) => {
    const { children, classes } = props;

    return (
        <Grid container className={classes.container}>
            <Grid item lg={6} className={classes.image} />
            <Grid item xs={12} lg={6} component={Paper} elevation={6} square>
                {children}
            </Grid>
        </Grid>
    );
};

export default withStyles(style)(AuthLayout);
