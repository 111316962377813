import React, {useState} from "react";
import {Button, IconButton, Paper, Typography, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {
    Timeline,
    TimelineConnector, TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@material-ui/lab";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import {withSnackbar, WithSnackbarProps} from "notistack";

import {ContainerDetailsParams, siteService} from "../../store/site";
import {Event, FeatureType} from "../../store/events";

import {getEventTypeName} from "../../utils/events.utils";
import {downloadCSV} from "../../utils/documents.utils";

import BigPicture from "../BigPicture";

import style from "./styles/containerEvents";

interface ContainerEventsProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    container: ContainerDetailsParams;
    events: Event[];
}

const ContainerEvents = (props: ContainerEventsProps) => {
    const { classes, container, enqueueSnackbar, events, t } =  props;

    const [showEventFeatures, setShowEventFeatures] = useState<string | undefined>();
    const [openImage, setOpenImage] = useState<string | undefined>();

    return (
        <>
            <div className={classes.card}>
                <Typography className={classes.cardTitle}>
                    {t("containerDetails.events")}
                    <IconButton
                        onClick={() => {
                            siteService.exportContainerEvents(container).subscribe(
                                (res) => {
                                    downloadCSV(res);
                                },
                                (err) => { enqueueSnackbar(t(err.key), { variant: "error" }) },
                            );
                        }}>
                        <img src="/images/dashboard/icon_export_data.svg" alt="" />
                    </IconButton>
                </Typography>
                <Timeline>
                    {events.map((event, index) => {
                        const features = event.features.filter((feature) => {
                            return feature.value || !!feature.valuePictures.length || !!feature.valueChecklist.length;
                        });

                        return (
                            <TimelineItem key={`${event.event_type} - ${index}`}>
                                <TimelineOppositeContent className={classes.timelineOpposite}>
                                    <Typography className={classes.date}>
                                        {t("containerDetails.date", { date: new Date(event.createdAt) })}
                                    </Typography>
                                    <Typography className={classes.hour}>
                                        {t("containerDetails.hour", { hour: new Date(event.createdAt) })}
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot className={classes.dot} color="inherit" />
                                    {index < events.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent className={classes.timelineContent}>
                                    <Typography className={classes.eventName}>
                                        {getEventTypeName(event.event_type)}
                                    </Typography>
                                    <Typography className={classes.eventOperator}>
                                        {`${event.operator.firstName} ${event.operator.lastName}`}
                                    </Typography>
                                    {features.length > 0 && (
                                        <Paper elevation={0} className={classes.paper}>
                                            <Button
                                                className={classes.showFeaturesButton}
                                                onClick={() => {
                                                    setShowEventFeatures(showEventFeatures === event.id ? undefined : event.id);
                                                }}
                                                endIcon={
                                                    showEventFeatures === event.id
                                                        ? <KeyboardArrowUp className={classes.showFeaturesEndIcon} />
                                                        : <KeyboardArrowDown className={classes.showFeaturesEndIcon} />
                                                }>
                                                {showEventFeatures === event.id ? t("containerDetails.hideFeatures") : t("containerDetails.showFeatures")}
                                            </Button>
                                            {showEventFeatures === event.id && features.map((feature) => {
                                                switch (feature.type) {
                                                    case FeatureType.BOOKING_NUMBER:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <Typography className={classes.featureValue}>{feature.value}</Typography>
                                                            </div>
                                                        );
                                                    case FeatureType.STUFFING_REFERENCE:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <Typography className={classes.featureValue}>{feature.value}</Typography>
                                                            </div>
                                                        );
                                                    case FeatureType.SEAL_NUMBER:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <Typography className={classes.featureValue}>{feature.value}</Typography>
                                                            </div>
                                                        );
                                                    case FeatureType.CHASSIS_NUMBER:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <Typography className={classes.featureValue}>{feature.value}</Typography>
                                                            </div>
                                                        );
                                                    case FeatureType.TRUCK_NUMBER:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <Typography className={classes.featureValue}>{feature.value}</Typography>
                                                            </div>
                                                        );
                                                    case FeatureType.TEXT_FIELD:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <Typography className={classes.featureValue}>{feature.value}</Typography>
                                                            </div>
                                                        );
                                                    case FeatureType.CHECK_LIST:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>                                                                <Typography className={classes.featureValue}>{feature.value}</Typography>
                                                                <Typography className={classes.featureValue}>
                                                                    {feature.valueChecklist.filter((v) => v.checked).map((v) => v.label).join(", ")}
                                                                </Typography>
                                                            </div>
                                                        );
                                                    case FeatureType.BOOLEAN:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <Typography className={classes.featureValue}>{feature.value}</Typography>
                                                            </div>
                                                        );
                                                    case FeatureType.IMAGE:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <div className={classes.featurePictures}>
                                                                    {feature.valuePictures.map((picture, index) => (
                                                                        <img
                                                                            key={`${feature.id} - pictures ${index + 1}`}
                                                                            alt=""
                                                                            onClick={() => { setOpenImage(picture); }}
                                                                            className={classes.featurePicture}
                                                                            src={picture} />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        );
                                                    case FeatureType.SIGNATURE:
                                                        return (
                                                            <div key={feature.id}>
                                                                <Typography className={classes.featureTitle}>{feature.label}</Typography>
                                                                <div className={classes.featurePictures}>
                                                                    {feature.valuePictures.map((picture, index) => (
                                                                        <img
                                                                            key={`${feature.id} - signature ${index + 1}`}
                                                                            alt=""
                                                                            onClick={() => { setOpenImage(picture); }}
                                                                            className={classes.featurePicture}
                                                                            src={picture} />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        );
                                                    default:
                                                        return null;
                                                }
                                            })}
                                        </Paper>
                                    )}
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })}
                </Timeline>
            </div>
            {openImage && (
                <BigPicture image={openImage} handleClose={() => { setOpenImage(undefined); }} />
            )}
        </>
    );
}

export default withStyles(style)(
    withTranslation()(withSnackbar(ContainerEvents))
);
