import React from "react";
import {
    Avatar,
    Button,
    Card,
    CardHeader,
    IconButton,
    Modal,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import {WithTranslation, withTranslation} from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";

import {sessionQuery, sessionService} from "../store/session";

import {getRoleName} from "../utils/roles.utils";

import style from "./styles/account";

interface AccountProps extends WithStyles<typeof style>, WithTranslation {
    handleClose: () => void;
}

class Account extends React.Component<AccountProps> {
    render() {
        const { classes, handleClose, t } = this.props;

        const user = sessionQuery.currentUser!;

        return (
            <Modal open onClose={handleClose}>
                <Card className={classes.paper}>
                    <CardHeader
                        className={classes.header}
                        action={(
                            <IconButton onClick={handleClose} className={classes.close}>
                                <CloseIcon />
                            </IconButton>
                        )}
                        classes={{ title: classes.title }}
                        title={t("account.myAccount")} />
                    <Avatar className={classes.avatar}>
                        {user && (user.firstName[0] + user.lastName[0])}
                    </Avatar>
                    <Typography className={classes.userName}>
                        {`${user?.firstName} ${user?.lastName}`}
                    </Typography>
                    <Typography className={classes.userRole}>
                        {t(getRoleName(sessionQuery.currentSite.role))}
                    </Typography>
                    <Button className={classes.logOut} onClick={() => { sessionService.logOut(); }}>
                        {t("account.logOut")}
                    </Button>
                </Card>
            </Modal>
        );
    }
}

export default withStyles(style)(
    withTranslation()(Account)
);
