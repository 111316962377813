import { createStyles } from "@material-ui/core";

const manageModules = () => createStyles({
    paper: {
        backgroundColor: 'white',
        border: 'none',
        borderRadius: 10,
        left: '50%',
        outline: 'none',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    header: {
        padding: '15px 20px 0px 40px',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
    },
    close: {
        color: '#1E2F4F',
        margin: 0,
    },
    content: {
        maxHeight: '55vh',
        overflow: 'auto',
        padding: '0 21px 0 40px',
        width: 'calc(100% - 21px - 40px)',
    },
    moduleCard: {
        borderRadius: 10,
        cursor: 'pointer',
        height: 210,
    },
    moduleCardSelected: {
        borderColor: 'rgb(20, 47, 85)',
        borderRadius: 10,
        height: 210,
        position: 'relative',
        top: 0,
    },
    moduleAction: {
        alignItems: 'stretch',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    moduleSelected: {
        backgroundColor: 'rgba(26, 47, 82, 0.21)',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        height: 'calc(100% - 10px - 10px)',
        padding: 11,
        position: 'absolute',
        width: 'calc(100% - 10px - 10px)',
    },
    selectedButton: {
        borderRadius: 10,
        height: 32,
        width: 32,
        zIndex: 1,
    },
    moduleImage: {
        height: 150,
        width: '100%',
    },
    moduleContent: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        justifyContent: 'stretch',
        padding: '0 20px',
    },
    moduleTitle: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 500,
    },
    save: {
        display: 'flex',
        justifyContent: 'center',
        padding: '30px 0',
    },
});

export default manageModules;
