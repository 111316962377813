import React from "react";
import {DataGrid} from "@material-ui/data-grid";
import {Pagination, PaginationItem} from "@material-ui/lab";
import {Card, withStyles, WithStyles} from "@material-ui/core";
import moment from "moment";

import {Container} from "../../store/site";

import style from "./styles/containersGrid";
import {getEventTypeName} from "../../utils/events.utils";
import {WithTranslation, withTranslation} from "react-i18next";
import {EventType} from "../../store/events";

interface ContainersGridProps extends WithStyles<typeof style>, WithTranslation {
    containers: Container[];
    getContainers: (page: number) => void;
    loading: boolean;
    onContainerClick: (container: Container) => void;
    rowCount: number;
}

const ContainersGrid = (props: ContainersGridProps) => {
    const { classes, containers, getContainers, loading, onContainerClick, rowCount, t } = props;

    return (
        <Card className={classes.tableContainer} elevation={3}>
            <DataGrid
                onRowClick={(params) => { onContainerClick(params.row as any); }}
                disableSelectionOnClick
                disableColumnMenu
                sortingOrder={[]}
                paginationMode="server"
                getRowId={(row) => `${row.serialNumber} - ${row.cycleId}`}
                components={{
                    Pagination: (params) => (
                        <Pagination
                            className={classes.pagination}
                            renderItem={(item) => (
                                <PaginationItem className={classes.paginationItem} {...item} />
                            )}
                            siblingCount={0}
                            count={params.state.pagination.pageCount}
                            page={params.state.pagination.page + 1}
                            onChange={(event, value) => params.api.current.setPage(value - 1)}
                            size="small" />
                    ),
                }}
                headerHeight={50}
                rowHeight={50}
                autoHeight
                className={classes.root}
                rows={containers}
                rowsPerPageOptions={[15]}
                pageSize={15}
                rowCount={rowCount}
                loading={loading}
                onPageChange={(param) => {
                    getContainers(param.page);
                }}
                columns={[
                    { field: 'serialNumber', headerName: t("containers.serialNumber"), flex: 1 },
                    {
                        field: 'user',
                        headerName: t("containers.user"),
                        flex: 1,
                        valueGetter: (params) => {
                            return `${params.row.operatorFirstName} ${params.row.operatorLastName}`
                        },
                    },
                    {
                        field: 'arrivalOnSite',
                        headerName: t("containers.firstEventDate"),
                        flex: 1,
                        valueFormatter: (params) => {
                            return t("containers.date", { date: new Date(params.value as string) });
                        },
                    },
                    {
                        field: 'lastEventDate',
                        headerName: t("containers.lastEventDate"),
                        flex: 1,
                        valueFormatter: (params) => {
                          return t("containers.date", { date: new Date(params.value as string) });
                        },
                    },
                    {
                        field: 'lastEventType',
                        headerName: t("containers.lastEvent"),
                        flex: 1,
                        valueGetter: (params) => {
                            return params.row.lastEventLabel !== ""
                                ? `${params.row.lastEventLabel} (${getEventTypeName(params.row.lastEventType).toLowerCase()})`
                                : getEventTypeName(params.row.lastEventType);
                        },
                    },
                    {
                        field: 'downTime',
                        headerName: t("containers.downTime"),
                        flex: 1,
                        valueFormatter: (params) => {
                            if ((params.row.lastEventType as EventType) === EventType.GATE_OUT) {
                                return "-";
                            }
                            return moment().diff(moment(params.row.arrivalOnSite), "d") || "0";
                        },
                    },
                    {
                        field: 'turnTime',
                        headerName: t("containers.turnTime"),
                        flex: 1,
                        valueFormatter: (params) => {
                            return moment(params.row.lastEventDate).diff(moment(params.row.arrivalOnSite), "h") || "0";
                        },
                    },
                ]} />
        </Card>
    );
};

export default withStyles(style)(
    withTranslation()(ContainersGrid)
);
