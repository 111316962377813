import { createStyles } from "@material-ui/core";

const containers = () => createStyles({
    container: {
        flex: 1,
    },
    gridContent: {
        padding: '40px 70px 25px',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 20,
        fontWeight: 700,
    },
    searchContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    dateContainer: {
        marginRight: 13,
    },
    selectContainer: {
        alignItems: 'center',
        backgroundColor: '#E7EAF3',
        borderRadius: 10,
        display: 'flex',
    },
    containerNumber: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'center',
        width: 200,
    },
    selectDivider: {
        backgroundColor: '#979797',
        height: 34,
        opacity: 0.4,
        width: 1,
    },
    actionsContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    exportDataButton: {
        borderRadius: 10,
        height: 50,
        marginLeft: 13,
        width: 50,
    },
    tableContainer: {
        paddingBottom: 40,
    },
});

export default containers;
