import { createStyles } from "@material-ui/core";

const containersGrid = () => createStyles({
    tableContainer: {
        borderRadius: 10,
        display: 'flex',
        margin: '0 70px',
    },
    root: {
        border: 'none',
        borderRadius: 10,
        '& .MuiDataGrid-colCellTitle': {
            color: '#1E2F4F',
            fontFamily: '"Roboto", sans-serif',
            fontSize: 13,
            fontWeight: 700,
        },
        '& .MuiDataGrid-colCell': {
            color: '#1E2F4F',
            fontFamily: '"Roboto", sans-serif',
            fontSize: 13,
            fontWeight: 400,
            outline: 'none !important',
            userSelect: 'none !important',
        },
        '& .MuiDataGrid-cell': {
            cursor: 'pointer',
            outline: 'none !important',
            userSelect: 'none !important',
        },
        '& .MuiDataGrid-window': {
            overflow: 'hidden',
        },
        "& .MuiDataGrid-columnSeparator": {
            display: 'none',
        },
    },
    pagination: {
        margin: '0 20px',
    },
    paginationItem: {
        color: '#108DFF',
    },
});

export default containersGrid;
