import {Query} from '@datorama/akita';
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";

import {sessionStore, SessionStore} from './session.store';
import {CurrentUser, moduleTypes, Session, UserOrganization} from "./session.model";
import {UserSite} from "../site";

export class SessionQuery extends Query<Session> {
    get currentUser(): CurrentUser { return this.getValue().user!; }

    get currentCompanyId(): string { return this.getValue().configuration.currentCompanyId!; }

    get currentCompany(): UserOrganization {
        return this.getValue().user!.organizations
            .find((company) => company.id === this.currentCompanyId)!;
    }

    get userCompanies(): UserOrganization[] { return this.getValue().user!.organizations; }

    get currentSiteId(): string { return this.getValue().configuration.currentSiteId!; }

    get currentSite(): UserSite {
        return this.currentCompany.sites
          .find((site) => site.id === this.currentSiteId)!;
    }

    get companiesForSelection(): { label: string, value: string }[] {
        return this.getValue().user!.organizations
            .map((company) => ({
                label: company.name,
                value: company.id,
            }));
    }

    get companySitesForSelection(): { label: string, value: string }[] {
        return this.currentCompany.sites
            .map((site) => ({
                label: site.name,
                value: site.id,
            }));
    }

    constructor(protected store: SessionStore) {
        super(store);
    }

    isAuthenticated$: Observable<boolean> =
        this.select("user").pipe(map((user) => !!user));

    currentIds$: Observable<{company: string; site: string}> = this.select((state) => ({
        company: state.configuration.currentCompanyId!,
        site: state.configuration.currentSiteId!,
    }));

    currentSite$: Observable<UserSite> =
        this.currentIds$
            .pipe(
                map(() => this.currentSite),
            );

    modulesOrganizationForSite$ =
        this.currentIds$
            .pipe(
                switchMap((ids) => {
                    return this.select((state) => {
                        return state.configuration.modulesOrganizations[ids.site] || moduleTypes;
                    });
                }),
            );
}

export const sessionQuery = new SessionQuery(sessionStore);
