import { createStyles } from "@material-ui/core";

const containersStillOnSite = () => createStyles({
    container: {
        borderRadius: 10,
        display: 'flex',
        minHeight: 400,
    },
    actionArea: {
        alignItems: 'flex-start',
        borderRadius: 10,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '20px 0px',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
        margin: '0 28px 20px',
    },
    xAxisTitle: {
        color: '#828A8C',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 500,
        marginLeft: 30,
    },
});

export default containersStillOnSite;
