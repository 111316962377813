import {createStyles} from "@material-ui/core";

const dashboardPermissionsCard = () => createStyles({
    container: {
        borderRadius: 10,
        height: '100%',
    },
    actionArea: {
        borderRadius: 10,
        height: '100%',
        padding: '19px 28px',
    },
    title: {
        color: '#1E2F4F',
        flexGrow: 1,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 14,
    },
    usersContainer: {
        alignItems: 'center',
        backgroundColor: '#F3F5F9',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 15px',
    },
    usersNumber: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
    },
    userAvatar: {
        backgroundColor: "#CDD1DD !important",
        borderColor: "#F3F5F9 !important",
        color: "#1E2F4F !important",
        fontFamily: '"Roboto", sans-serif',
        fontSize: 12,
        fontWeight: 500,
    },
    moreUsers: {
        backgroundColor: "#FFFFFF",
        borderColor: "#F3F5F9",
        color: "#1E2F4F",
        fontFamily: '"Roboto", sans-serif',
        fontSize: 12,
        fontWeight: 500,
    },
});

export { dashboardPermissionsCard };
