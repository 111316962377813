import {createStyles} from "@material-ui/core";

const bigPicture = () => createStyles({
    container: {
        backgroundColor: 'black',
    },
    title: {
        padding: 0,
        "& > :first-child": {
            position: 'absolute',
            right: 10,
            top: 10,
        },
    },
    closeIcon: {
        backgroundColor: 'rgba(125, 125, 125, 0.3)',
    },
    content: {
        padding: 0,
    },
    image: {
        height: '90vh',
        objectFit: 'contain',
    },
});

export default bigPicture;
