import { createStyles } from "@material-ui/core";

const moduleOrganization = () => createStyles({
    row: {
       display: 'flex',
    },
    droppable: {
        display: 'flex',
        marginTop: 19,
    },
});

export default moduleOrganization;
