import {createStyles} from "@material-ui/core";

const authTextFields = () => createStyles({
    authTextField: {
        marginBottom: 0,
        marginTop: 14,
        minWidth: 150,
    },
    authTextFieldLabel: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
    },
    authTextFieldLabelFocused: {
        color: '#1E2F4F !important',
    },
    authTextFieldRoot: {
        backgroundColor: 'rgb(244, 245, 249)',
        color: '#1E2F4F',
        fontSize: 14,
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        '&:hover': {
            backgroundColor: 'rgb(239, 240, 244)',
            '@media (hover: none)': {
                backgroundColor: 'rgb(244, 245, 249)',
            },
        },
        '&$focused': {
            backgroundColor: 'rgb(239, 240, 244)',
        },
    },
    focused: {
        opacity: 1,
    },
    authTextFieldUnderline: {
        '&:after': {
            borderWidth: 2.5,
        },
        '&:hover:before': {
            borderColor: '#43A5FF !important',
        },
    },
});

const grayInput = () => createStyles({
    input: {
        borderRadius: 10,
        backgroundColor: '#F3F5F9',
        border: 'none',
        color: '#1E2F4F',
        fontSize: 14,
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 700,
        height: 'calc(50px - 16px - 16px)',
        minWidth: 'calc(190px - 19px - 19px)',
        padding: '16px 19px',
        position: 'relative',
        '&:focus': {
            backgroundColor: '#F3F5F9',
            borderRadius: 10,
        },
    },
});

const transparentInput = () => createStyles({
    input: {
        backgroundColor: 'white',
        border: 'none',
        color: '#7E889A',
        fontSize: 13,
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        height: 10,
        position: 'relative',
        '&:focus': {
            backgroundColor: 'white',
        },
    },
});

const darkGrayInput = () => createStyles({
    input: {
        borderRadius: 10,
        backgroundColor: '#E7EAF3',
        border: 'none',
        color: '#1E2F4F',
        fontSize: 14,
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 700,
        height: 'calc(50px - 16px - 16px)',
        minWidth: 'calc(190px - 19px - 19px)',
        padding: '16px 19px',
        position: 'relative',
        '&:focus': {
            backgroundColor: '#E7EAF3',
            borderRadius: 10,
        },
    },
});

const selectTextFields = () => createStyles({
    selectMenuPaper: {
        backgroundColor: 'white',
        borderRadius: 10,
    },
    selectMenuList: {
        borderRadius: 10,
        padding: '5px 0',
        margin: 0,
        marginLeft: 20,
    },
    iconBlack: {
        color: '#1E2F4F',
    },
    iconGray: {
        color: '#7E889A',
    },
    menuItem: {
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        padding: '10px 20px 10px 0',
    },
    menuItemBordered: {
        borderBottom: '1px solid #F3F5F9',
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        padding: '10px 20px 10px 0',
    },
    menuItemSelected: {
        backgroundColor: 'transparent !important',
    },
});

const darkGrayTextField = () => createStyles({
    calendar: {
        backgroundColor: '#43A5FF',
    },
    root: {
        borderRadius: 10,
        backgroundColor: '#E7EAF3',
        color: '#1E2F4F',
        cursor: 'pointer',
        fontSize: 14,
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        height: 50,
        '&:hover': {
            backgroundColor: '#E7EAF3',
            '@media (hover: none)': {
                backgroundColor: '#E7EAF3',
            },
        },
        '&$focused': {
            backgroundColor: '#E7EAF3',
        },
    },
    focused: {
        opacity: 1,
    },
    input: {
        cursor: 'pointer',
    },
    inputIcon: {
        height: 16,
        width: 16,
    },
    label: {
        color: 'rgba(30, 47, 79, 0.5)',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
    },
    labelFocused: {
        color: 'rgba(30, 47, 79, 0.5) !important',
    },
});


const autoComplete = () => createStyles({
    container: {
        flex: 1,
        minWidth: 250,
    },
    root: {
        borderRadius: 10,
        color: '#1E2F4F',
        fontSize: 14,
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 400,
        height: 50,
        '&:hover': {
            backgroundColor: '#E7EAF3',
            '@media (hover: none)': {
                backgroundColor: '#E7EAF3',
            },
        },
        '&$focused': {
            backgroundColor: '#E7EAF3',
        },
    },
    focused: {
        opacity: 1,
    },
    input: {
        border: 'none',
        height: 50,
        '&::placeholder': {
            color: '#7E889A',
            fontSize: 14,
            fontFamily: '"Roboto", sans-serif',
            fontWeight: 400,
        },
    },
    paper: {
        backgroundColor: 'white',
        borderRadius: 10,
        margin: 0,
        padding: 0,
    },
    listBox: {
        borderRadius: 10,
        padding: '5px 0',
        margin: 0,
        marginLeft: 20,
    },
    option: {
        borderBottom: '1px solid #F3F5F9',
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        minHeight: 0,
        padding: '10px 20px 10px 0',
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
    },
});

export { authTextFields, autoComplete, darkGrayInput, darkGrayTextField, grayInput, selectTextFields, transparentInput };
