import React, {useEffect, useState} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {FormControlLabel, FormGroup, Radio, RadioGroup, Typography} from "@material-ui/core";
import {withSnackbar, WithSnackbarProps} from "notistack";

import {Role, SitePermission, siteQuery, siteService} from "../../store/site";

import {getRoleName} from "../../utils/roles.utils";

import ManageUsers from "../ManageUsers";

interface ManagePermissionsProps extends WithTranslation, WithSnackbarProps {
    handleClose: () => void;
}

const ManagePermissions = (props: ManagePermissionsProps) => {
    const { enqueueSnackbar, handleClose, t } = props;

    const [permissions, setPermissions] = useState<SitePermission[]>([]);

    useEffect(() => {
        const permissions = siteQuery.getPermissions$.subscribe((perms) => {
            setPermissions(perms);
        });

        return () => permissions.unsubscribe();
    }, []);

    return (
        <ManageUsers
            handleAddUser={(evt, onSuccess) => {
                evt.preventDefault();

                const email = (evt.target as any)["email"].value;
                const role = (evt.target as any)["role"].value;

                siteService.addSitePermission(email, role).subscribe(
                    (successKey) => {
                        if (successKey) enqueueSnackbar(t(successKey, { email }), { variant: "success" });
                        onSuccess();
                    },
                    (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                )
            }}
            handleClose={handleClose}
            handleRemoveUser={(userId) => {
                siteService.removeSitePermission(userId).subscribe(
                    () => {},
                    (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                );
            }}
            title={t("permissions.dashboardPermissions")}
            formInputs={[
                (
                    <FormGroup>
                        <Typography variant="subtitle1">{t("roles.label")}</Typography>
                        <RadioGroup row name="role" defaultValue={Role.VIEWER}>
                            <FormControlLabel
                                value={Role.VIEWER}
                                control={<Radio color="primary" size="small" />}
                                label={t(getRoleName(Role.VIEWER))} />
                            <FormControlLabel
                                value={Role.ADMIN}
                                control={<Radio color="primary" size="small" />}
                                label={t(getRoleName(Role.ADMIN))} />
                        </RadioGroup>
                    </FormGroup>
                )
            ]}
            isSitePermissions
            permissions={permissions} />
    );
}

export default withTranslation()(withSnackbar(ManagePermissions));
