import React from 'react';
import {Button, Checkbox, FormControlLabel, WithStyles, withStyles} from "@material-ui/core";

import style from './styles/buttons';

interface ClassicButtonProps extends WithStyles<typeof style> {
    onClick?: () => void;
    title: string;
    type?: "button" | "submit" | "reset" | undefined;
}

const ClassicButton = withStyles(style)((props: ClassicButtonProps) => {
    const { classes, onClick, title, type } = props;

    return (
        <Button className={classes.classicButton} onClick={onClick} type={type}>
            {title}
        </Button>
    );
});

interface DefaultButtonProps extends WithStyles<typeof style> {
    onClick?: () => void;
    icon?: string;
    title: string;
    type?: "button" | "submit" | "reset" | undefined;
}

const DefaultButton = withStyles(style)((props: DefaultButtonProps) => {
    const { classes, icon, onClick, title, type } = props;

    return (
        <Button className={classes.defaultButton} onClick={onClick} type={type}>
            {title}
            {icon && <img className={classes.defaultIcon} src={icon} alt="" />}
        </Button>
    );
});

interface CustomCheckboxProps extends WithStyles<typeof style> {
    checked?: boolean;
    handleChange?: () => void;
    name?: string;
    label: string;
}

const CustomCheckbox = withStyles(style)((props: CustomCheckboxProps) => {
    const { checked, classes, handleChange, label, name } = props;

    return (
        <FormControlLabel
            className={classes.customCheckboxControl}
            classes={{ label: classes.customCheckboxLabel }}
            name={name}
            control={
                <Checkbox
                    classes={{
                        root: classes.customCheckboxContainer,
                        checked: classes.customCheckboxChecked,
                    }}
                    size="small"
                    checked={checked}
                    onChange={handleChange} />
            }
            label={label} />
    );
});

export { CustomCheckbox, ClassicButton, DefaultButton };
