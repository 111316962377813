import React, {useEffect, useState} from "react";
import {Card, Typography, withStyles, WithStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {Bubble} from "react-chartjs-2";
import {switchMap} from "rxjs/operators";
import {withSnackbar, WithSnackbarProps} from "notistack";

import {ContainerDetailsParams, Filters, siteService, TurnTimeEvent} from "../../store/site";
import {sessionQuery} from "../../store/session";

import style from "./styles/turnTimeOfEachEvent";
import {getEventTypeName} from "../../utils/events.utils";
import {DateTextField} from "../TextFields";
import moment from "moment";
import {median} from "../../utils/modules.utils";

interface TurnTimeOfEachEventProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    filters: Filters;
    onPointClick: (containers: ContainerDetailsParams[]) => void;
}

const bubbleOptions = {
    interaction: {
        mode: 'index',
    },
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            type: 'category',
            offset: true,
            gridLines: {
                drawTicks: false,
                zeroLineWidth: 0,
                offsetGridLines: true,
            },
            ticks: {
                padding: 10,
            },
        }],
        yAxes: [{
            gridLines: {
                drawTicks: false,
                zeroLineWidth: 0,
                lineWidth: [1, 0, 0, 0, 1]
            },
            ticks: {
                beginAtZero: true,
                stepSize: 20,
                suggestedMax: 80,
                padding: 10,
            },
        }],
    },
};

const TurnTimeOfEachEvent = (props: TurnTimeOfEachEventProps) => {
    const { classes, enqueueSnackbar, filters, onPointClick, t } = props;

    const [events, setEvents] = useState<TurnTimeEvent[]>([]);

    const [dates, setDates] = useState<{ startDate: Date, endDate: Date }>({
        startDate: moment().subtract(1, "w").toDate(),
        endDate: moment().toDate(),
    });

    useEffect(() => {
        const timeLapse = sessionQuery.currentSite$.pipe(
            switchMap(() => {
                return siteService.fetchTurnTimeEvents({ ...filters, startDate: dates.startDate, endDate: dates.endDate });
            }),
        ).subscribe(
            (e) => {
                setEvents(e);
            },
            (err) => { enqueueSnackbar(t(err.key), { variant: "error"}); },
        );

        return () => timeLapse.unsubscribe();
    }, [dates, enqueueSnackbar, filters, t]);

    const eventsLabel = events.map((evt) => evt.eventTemplateName || getEventTypeName(evt.eventTemplateType));

    const medians = events.reduce((data, event) => {
        const eventName: string = event.eventTemplateName || getEventTypeName(event.eventTemplateType);

        const currentMedian = median(event.turnTimes.map((tt) => tt.minutesElapsed));

        data.push({ x: eventName, y: currentMedian, label: t("modules.medianLabel") });

        return data;
    }, [] as { x: string; y: number; label: string }[]);

    const mappedData = events.reduce((data, event) => {
        const eventName: string = event.eventTemplateName || getEventTypeName(event.eventTemplateType);

        const times = event.turnTimes.map((tt) => ({
            x: eventName,
            y: tt.minutesElapsed,
            containerId: tt.containerId,
            serialNumber: tt.serialNumber,
            cycleId: tt.cycleId,
            operator: `${tt.operatorFirstName} ${tt.operatorLastName}`,
        }));

        times.forEach((tt) => data.push(tt));

        return data;
    }, [] as { x: string; y: number; containerId: string; serialNumber: string; cycleId: string; operator: string }[]);

    return (
        <Card elevation={3} className={classes.container}>
            <Typography className={classes.title}>
                {t('modules.turnTimeOfEachEvent')}
            </Typography>
            <Bubble
                options={{
                    ...bubbleOptions,
                    onClick: function(e: any) {
                        let element = this.getElementsAtEvent(e).find((el: any) => el._datasetIndex === 0);

                        if (!element) {
                            return ;
                        }

                        const elementData = this.config.data.datasets[element._datasetIndex].data[element._index];

                        const elements = this.config.data.datasets[0].data.filter(
                            (d: any) => d.x === elementData.x && d.y === elementData.y
                        );

                        if (!elements.length) {
                            return ;
                        }

                        onPointClick(elements.map((el: any) => ({ containerId: el.containerId, serialNumber: el.serialNumber, cycleId: el.cycleId })));
                    },
                    tooltips: {
                        callbacks: {
                            title: function(tooltipItem: any[]) {
                                return t("modules.minutes", { minutes: tooltipItem[0]["value"]});
                            },
                            label: function(tooltipItem: any, element: any) {
                                const data = element.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                                if (data.label) return data.label;

                                return t("modules.turnTimeContainerDetail", { data });
                            },
                        },
                    },
                }}
                height={140}
                data={{
                    labels: eventsLabel,
                    datasets: [{
                        label: "data",
                        backgroundColor: "#43A5FF",
                        radius: 6,
                        hoverRadius: 0,
                        data: mappedData,
                    },
                    {
                        label: t("modules.medianLabel"),
                        backgroundColor: "#FF0000",
                        borderColor: "#FF0000",
                        pointStyle: "line",
                        radius: 15,
                        hoverRadius: 0,
                        borderWidth: 3,
                        hoverBorderWidth: 3,
                        data: medians,
                    }],
                }} />
            <div className={classes.dateRow}>
                <div className={classes.dateContainer}>
                    <DateTextField
                        date={dates.startDate}
                        fullWidth
                        handleChange={(date) => {
                            if (date) {
                                const endDate = date.isAfter(filters.endDate) ? date.toDate() : filters.endDate;
                                setDates({ startDate: date.toDate(), endDate });
                            }
                        }}
                        label={t("input.dateFrom")} />
                </div>
                <div className={classes.dateContainer}>
                    <DateTextField
                        date={dates.endDate}
                        fullWidth
                        handleChange={(date) => {
                            if (date) {
                                const startDate = date.isBefore(dates.startDate) ? date.toDate() : dates.startDate;
                                setDates({ startDate, endDate: date.toDate() });
                            }
                        }}
                        label={t("input.dateTo")} />
                </div>
            </div>
        </Card>
    )
}

export default withStyles(style)(
    withTranslation()(withSnackbar(TurnTimeOfEachEvent))
);
