import {useEffect, useRef} from "react";
import {createPortal} from "react-dom";
import {DragUpdate, DropResult} from "react-beautiful-dnd";

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export const onDragEnd = (result: DropResult | DragUpdate, list: any[], updateOrder: (newList: any[]) => void) => {
    if (!result.destination) {
        return;
    }

    const reorderedList = reorder(
        list,
        result.source.index,
        result.destination.index
    );

    updateOrder(reorderedList);
}

export const useDraggableInPortal = () => {
    const self = useRef({} as any).current;

    useEffect(() => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.top = '0';
        div.style.width = '100%';
        div.style.height = '100%';
        self.elt = div;
        document.body.appendChild(div);

        return () => {
            document.body.removeChild(div);
        };
    }, [self]);

    return (render: any) => (provided: any, ...args: any) => {
        const element = render(provided, ...args);

        if (provided.draggableProps.style.position === 'fixed') {
            return createPortal(element, self.elt);
        }

        return element;
    };
}
