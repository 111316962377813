import { createStyles } from "@material-ui/core";

const addEvent = () => createStyles({
    container: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        height: 100,
        textTransform: 'none',
        width: 290,
    },
    action: {
        borderRadius: 10,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
    },
    title: {
        color: '#43A5FF',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        marginLeft: 10,
    },
    newEventContent: {
        padding: '0px 0px 0px 18px',
    },
    newEventItem: {
        borderColor: 'rgba(151,151,151, 0.2)',
        padding: 12,
    },
});

export default addEvent;
