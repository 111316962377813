import axios, {AxiosRequestConfig} from "axios";
import keycloak from "./keycloak.utils";

const newAxios = axios.create({
		baseURL: process.env.REACT_APP_API_HOST!,
})

newAxios.interceptors.request.use(async (config) => {
	if (keycloak.isTokenExpired()) {
		await keycloak.updateToken(180);
		config.headers.Authorization = keycloak.token;
	} else {
		if (keycloak.token) {
			config.headers.Authorization = keycloak.token;
		}
	}

	return config;
});

export const APIRoutes = {
		currentUser: (): AxiosRequestConfig => ({ method: "get", url: "/users/manager/me" }),

		/* EVENTS REQUESTS */
		eventTemplates: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/event-templates` }),
		updateEventTemplate: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "patch", url: `/sites/${siteId}/event-templates/${eventId}` }),
		createEventTemplate: (siteId: string): AxiosRequestConfig => ({ method: "post", url: `/sites/${siteId}/event-templates` }),
		deleteEventTemplate: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "delete", url: `/sites/${siteId}/event-templates/${eventId}` }),
		addFeature: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "post", url: `/sites/${siteId}/event-templates/${eventId}/features` }),
		updateFeature: (siteId: string, eventId: string, featureId: string): AxiosRequestConfig => ({ method: "patch", url: `/sites/${siteId}/event-templates/${eventId}/features/${featureId}` }),
		removeFeature: (siteId: string, eventId: string, featureId: string): AxiosRequestConfig => ({ method: "delete", url: `/sites/${siteId}/event-templates/${eventId}/features/${featureId}` }),
		addAttribution: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "post", url: `/sites/${siteId}/event-templates/${eventId}/attributions` }),
		removeAttribution: (siteId: string, eventId: string, userId: string): AxiosRequestConfig => ({ method: "delete", url: `/sites/${siteId}/event-templates/${eventId}/attributions/${userId}` }),
		/* --------------- */

		/* SITE PERMISSIONS */
		sitePermissions: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/permissions` }),
		addSitePermission: (siteId: string): AxiosRequestConfig => ({ method: "post", url: `/sites/${siteId}/permissions` }),
		removeSitePermission: (siteId: string, userId: string): AxiosRequestConfig => ({ method: "delete", url: `/sites/${siteId}/permissions/${userId}` }),
		/* ---------------- */

		/* STATISTICS */
		containersStillOnSite: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/statistics/still-on-site` }),
		lastContainersEvents: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/statistics/last-containers-events` }),
		timeLapsOnSite: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/statistics/timelaps-on-site` }),
		locationOnSite: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/statistics/location-on-site` }),
		truckTimeLapse: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/statistics/timelaps-on-site-trucks` }),
		chassisTimeLapse: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/statistics/timelaps-on-site-frames` }),
		turnTimeEvents: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/statistics/turn-time-events` }),
		/* ---------- */

		/* CONTAINERS */
		containersForEvent: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/containers-modules/containers-for-event-type/${eventId}` }),
		containersWithLocation: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/containers-modules/containers-location` }),
		containers: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/containers-modules/containers-still-on-site` }),
		containerDetails: (containerId: string): AxiosRequestConfig => ({ method: "get", url: `/containers/${containerId}` }),
		containerEvents: (siteId: string, containerId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/containers/${containerId}/events` }),
		trucks: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/trucks` }),
		chassis: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/frames` }),
		/* ---------- */

		/* AUTOCOMPLETE */
		autoCompleteContainers: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/autocomplete/containers` }),
		autoCompleteChassis: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/autocomplete/frames` }),
		autoCompleteTrucks: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/autocomplete/trucks` }),
		/* ------------ */

		/* EXPORTS */
		exportContainersForEvent: (siteId: string, eventId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/containers-modules/containers-for-event-type/${eventId}/export`, responseType: "blob" }),
		exportContainers: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/containers-modules/containers-still-on-site/export`, responseType: "blob" }),
		exportChassis: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/frames/export`, responseType: "blob" }),
		exportTrucks: (siteId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/trucks/export`, responseType: "blob" }),
		exportContainerEvents: (siteId: string, containerId: string): AxiosRequestConfig => ({ method: "get", url: `/sites/${siteId}/containers/${containerId}/events/export`, responseType: "blob" }),
		/* ------- */
};

export default newAxios;
