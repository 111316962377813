import { createStyles } from "@material-ui/core";

const manageUsers = () => createStyles({
    paper: {
        backgroundColor: 'white',
        border: 'none',
        borderRadius: 10,
        left: '50%',
        outline: 'none',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
    },
    header: {
        padding: '22px 15px 0 26px',
    },
    title: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
    },
    close: {
        color: '#1E2F4F',
        margin: 0,
    },
    content:  {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
    contentHeader: {
        alignItems: 'center',
        borderBottom: '1px solid #EAEAEA',
        display: 'flex',
        padding: '0 10px 25px 26px',
        marginTop: -14,
        width: 'calc(100% - 10px - 26px)',
    },
    addButton: {
        color: '#43A5FF',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        marginLeft: 20,
        marginTop: 14,
        textTransform: 'none',
        width: 100,
    },
    users: {
        maxHeight: '45vh',
        overflow: 'auto',
        padding: '0 0 0 26px',
        width: 'calc(100% - 26px)',
    },
    user: {
        padding: '10px 0 10px 0',
    },
    avatar: {
        backgroundColor: '#CDD1DD !important',
        color: '#1E2F4F !important',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 12,
        fontWeight: 400,
        height: 36,
        width: 36,
    },
    userName: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 500,
    },
    company: {
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
    },
    userEvents: {
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
    },
    addUserFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 64px',
    },
    addUserTitle: {
        alignSelf: 'center',
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
        margin: '30px 0 15px',
    },
    inputContainer: {
        marginTop: 15,
    },
    emailIcon: {
        height: 16,
    },
    addUserButton: {
        margin: '30px auto',
    },
});

export default manageUsers;
