import {arrayAdd, arrayRemove, arrayUpsert, EntityState, EntityStore, StoreConfig} from '@datorama/akita';

import {EventTemplate, FeatureTemplate} from './events.model';
import {User} from "../session";

export interface EventsState extends EntityState<EventTemplate, string> {}

@StoreConfig({ name: 'events', resettable: true })
export class EventsStore extends EntityStore<EventsState> {

		addFeature = (eventId: string, feature: FeatureTemplate) => {
				this.upsert(
						eventId,
						(event) => ({
								features: arrayAdd((event as EventTemplate).features || [], feature),
						}),
				);
		}

		updateFeature = (eventId: string, feature: FeatureTemplate) => {
				this.upsert(
						eventId,
						(event) => ({
								features: arrayUpsert((event as EventTemplate).features || [], feature.id!, feature),
						}),
				);
		}

		removeFeature = (eventId: string, featureId: string) => {
				this.upsert(
						eventId,
						(event) => ({
								features: arrayRemove((event as EventTemplate).features || [], featureId),
						}),
				);
		}

		addAttribution = (eventId: string, user: User) => {
				this.upsert(
						eventId,
						(event) => ({
								attributions: arrayAdd((event as EventTemplate).attributions || [], user),
						}),
				);
		}

		removeAttribution = (eventId: string, userId: string) => {
				this.upsert(
						eventId,
						(event) => ({
								attributions: arrayRemove((event as EventTemplate).attributions || [], userId),
						}),
				);
		}
}

export const eventsStore = new EventsStore();
