import { createStyles } from "@material-ui/core";

const siteProcess = () => createStyles({
    container: {
        borderRadius: 10,
        height: '100%',
        position: 'relative',
    },
    actionArea: {
        borderRadius: 10,
        height: '100%',
        padding: '18px 5px 10px 28px',
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    arrowButtonsContainer: {
        position: 'absolute',
        right: 28,
        top: 18,
        zIndex: 1,
    },
    arrowButton: {
        backgroundColor: '#F3F5F9',
        color: '#1E2F4F',
        margin: '0 10px',
    },
    title: {
        color: '#1E2F4F',
        flexGrow: 1,
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 500,
    },
    stepper: {
      marginRight: 40,
    },
    label: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 13,
        fontWeight: 400,
    },
    step: {
        color: '#43A5FF',
    },
    active: {},
    completed: {},
});

export default siteProcess;
