import React from "react";
import {Button, CardContent, ClickAwayListener, FormGroup, Typography, WithStyles, withStyles} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import {withSnackbar, WithSnackbarProps} from "notistack";

import {
    createNewFeature,
    eventsService,
    EventTemplate,
    featuresTypes,
    FeatureTemplate,
    FeatureType
} from "../../store/events";

import ActionModal from "../ActionModal";
import {AuthTextField} from "../TextFields";
import {CustomCheckbox} from "../Buttons";

import style from "./styles/feature";
import {getFeatureIcon, getFeatureTypeName} from "../../utils/events.utils";

enum OpenModalState {
    NONE,
    EDIT_FEATURE,
    FEATURE_LIST,
}

interface FeatureProps extends WithStyles<typeof style>, WithTranslation, WithSnackbarProps {
    event: EventTemplate;
    feature?: FeatureTemplate;
    isAddFeature?: boolean;
}

interface FeatureState {
    feature?: FeatureTemplate,
    selected: FeatureType;
    openModalState: OpenModalState;
}

class Feature extends React.Component<FeatureProps, FeatureState> {
    constructor(props: FeatureProps) {
        super(props);

        this.state = {
            feature: props.feature,
            selected: featuresTypes[0],
            openModalState: OpenModalState.NONE,
        };
    }

    handleCloseModal = () => {
        const { feature } = this.props;
        this.setState({ openModalState: OpenModalState.NONE, feature });
    }

    handleFeatureChange = (name: string, value: any) => {
        const { feature } = this.state;
        this.setState({ feature: { ...feature!, [name]: value } });
    }

    renderFeatureList = () => {
        const { classes, event, t } = this.props;

        const { selected } = this.state;

        const features = featuresTypes.filter((type) => {
           switch (type) {
               case FeatureType.LOCATION:
               case FeatureType.BOOKING_NUMBER:
               case FeatureType.STUFFING_REFERENCE:
               case FeatureType.SIGNATURE:
                   return !event.features.find((f) => f.type === type);
               default:
                   return true;
           }
        });

        return (
            <ClickAwayListener onClickAway={this.handleCloseModal}>
                <div className={classes.featuresList}>
                    <ActionModal
                        handleCancel={this.handleCloseModal}
                        handleOk={() => {
                            this.setState({
                                openModalState: OpenModalState.EDIT_FEATURE,
                                selected: featuresTypes[0],
                                feature: createNewFeature(selected),
                            });
                        }}
                        title={t("events.addInformation")}>
                        <CardContent className={classes.featuresContent}>
                            {features.map((type, index) => (
                                <Button
                                    key={`add feature ${type} - ${index}`}
                                    className={selected === type ? classes.featureButtonSelected : classes.featureButton}
                                    onClick={() => { this.setState({ selected: type }) }}>
                                    <img className={classes.featureButtonIcon} src={getFeatureIcon(type)} alt="" />
                                    {getFeatureTypeName(type)}
                                </Button>
                            ))}
                        </CardContent>
                    </ActionModal>
                </div>
            </ClickAwayListener>
        );
    }

    renderEditFeatureOptions = () => {
        const { classes, t } = this.props;
        const { feature } = this.state;

        if (!feature) return null;

        switch (feature.type) {
            case FeatureType.TRUCK_NUMBER:
            case FeatureType.CHASSIS_NUMBER:
            case FeatureType.BOOKING_NUMBER:
            case FeatureType.STUFFING_REFERENCE:
            case FeatureType.SEAL_NUMBER:
                return (
                    <>
                        <Typography className={classes.recoverWayTitle}>
                            {t("events.recoveryWay")}
                        </Typography>
                        <FormGroup>
                            <CustomCheckbox
                                checked={feature.ocr}
                                handleChange={() => {
                                    this.handleFeatureChange("ocr", !feature.ocr);
                                }}
                                label="OCR" />
                            <CustomCheckbox
                                checked={feature.qrcode}
                                handleChange={() => {
                                    this.handleFeatureChange("qrcode", !feature.qrcode);
                                }}
                                label="QR code" />
                        </FormGroup>
                    </>
                );
            case FeatureType.BOOLEAN:
                return (
                    <>
                        <Typography className={classes.optionsTitle}>
                            {t("events.options")}
                        </Typography>
                        <AuthTextField
                            label={t("events.option", { option: 1 })}
                            value={feature.options[0] || ''}
                            required
                            handleKeyDown={(event) => {
                                if (event.keyCode === 13) event.preventDefault();
                            }}
                            handleChange={(event) => {
                                this.handleFeatureChange(
                                    "options",
                                    [event.target.value, feature.options[1]]
                                );
                            }} />
                        <AuthTextField
                            label={t("events.option", { option: 2 })}
                            value={feature.options[1] || ''}
                            required
                            handleKeyDown={(event) => {
                                if (event.keyCode === 13) event.preventDefault();
                            }}
                            handleChange={(event) => {
                                this.handleFeatureChange(
                                    "options",
                                    [feature.options[0], event.target.value]
                                );
                            }} />
                    </>
                );
            case FeatureType.CHECK_LIST:
                return (
                    <>
                        <Typography className={classes.optionsTitle}>
                            {t("events.options")}
                        </Typography>
                        <AuthTextField
                            label={t("events.option", { option: 1 })}
                            value={feature.options[0] || ''}
                            required
                            handleKeyDown={(event) => {
                                if (event.keyCode === 13) event.preventDefault();
                            }}
                            handleChange={(event) => {
                                let options = [...feature.options];
                                options[0] = event.target.value;

                                this.handleFeatureChange("options", options);
                            }} />
                        {feature.options.length > 1 && feature.options.slice(1, feature.options.length).map((option, index) => (
                            <AuthTextField
                                key={`option-${index + 2}`}
                                label={t("events.option", { option: index + 2 })}
                                value={feature.options[index + 1] || ''}
                                required
                                handleKeyDown={(event) => {
                                    if (!feature.options[index + 1] && event.keyCode === 8) {
                                        let options = [...feature.options];
                                        options = options.filter((_, i) => i !== index + 1);

                                        this.handleFeatureChange("options", options);
                                    } else if (event.keyCode === 13) {
                                        event.preventDefault();
                                    }
                                }}
                                handleChange={(event) => {
                                    let options = [...feature.options];
                                    options[index + 1] = event.target.value;

                                    this.handleFeatureChange("options", options);
                                }} />
                        ))}
                        <Button
                            className={classes.addOptionButton}
                            onClick={() => {
                                let options = [...feature.options];
                                options.push("");

                                this.handleFeatureChange("options", options);
                            }}>
                            <img className={classes.featureButtonIcon} src="/images/events/icon_add_feature.svg" alt="" />
                            {t("events.addOption")}
                        </Button>
                    </>
                );
            default:
                return null;
        }
    }

    renderEditFeature = () => {
        const { classes, enqueueSnackbar, event, feature: defaultFeature, isAddFeature, t } = this.props;

        const { feature } = this.state;

        if (!feature) return null;

        const featureType = getFeatureTypeName(feature.type);
        const featureTitle = defaultFeature?.label || featureType;

        return (
            <ClickAwayListener onClickAway={this.handleCloseModal}>
                <div className={classes.editFeatureContainer}>
                    <ActionModal
                        handleCancel={() => {
                            if (isAddFeature) {
                                this.setState({ openModalState: OpenModalState.FEATURE_LIST, feature: undefined });
                            } else {
                                this.handleCloseModal();
                            }
                        }}
                        handleOk={() => {
                            if (isAddFeature) {
                                eventsService.addFeature(event.id, feature).subscribe(
                                    () => { this.handleCloseModal(); },
                                    (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                                );
                            } else {
                                eventsService.updateFeature(event.id, feature).subscribe(
                                    () => { this.handleCloseModal(); },
                                    (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                                );
                            }
                        }}
                        title={isAddFeature ? t("events.addInformation") : featureTitle}>
                        <CardContent className={classes.editFeatureContent}>
                            <div className={classes.editFeatureHeader}>
                                <img alt="" src={getFeatureIcon(feature.type)} />
                                <Typography className={classes.editFeatureType}>
                                    {featureType}
                                </Typography>
                                {!isAddFeature && <Button
                                    className={classes.editFeatureDelete}
                                    onClick={() => {
                                        eventsService.removeFeature(event.id, feature.id!).subscribe(
                                            () => { this.handleCloseModal(); },
                                            (err) => { enqueueSnackbar(t(err.key), { variant: err.variant }); },
                                        );
                                    }}>
                                    {t("events.delete")}
                                </Button>}
                            </div>
                            <AuthTextField
                                label={t("input.name")}
                                value={feature.label}
                                required
                                handleChange={(event) => {
                                    this.handleFeatureChange("label", event.target.value);
                                }} />
                            {this.renderEditFeatureOptions()}
                        </CardContent>
                    </ActionModal>
                </div>
            </ClickAwayListener>
        );
    }

    renderModal = () => {
        const { openModalState } = this.state;

        switch (openModalState) {
            case OpenModalState.EDIT_FEATURE:
                return this.renderEditFeature();
            case OpenModalState.FEATURE_LIST:
                return this.renderFeatureList();
            default:
                return null
        }
    }

    render() {
        const { classes, feature, isAddFeature, t } = this.props;

        const featureTitle = feature ? feature.label || getFeatureTypeName(feature.type) : undefined;

        return (
            <div className={classes.container}>
                {!isAddFeature ? (
                    <Button
                        className={classes.featureButton}
                        onClick={() => {
                            this.setState({ openModalState: OpenModalState.EDIT_FEATURE });
                        }}>
                        <img className={classes.featureButtonIcon} src={getFeatureIcon(feature!.type)} alt="" />
                        {featureTitle}
                    </Button>
                ) : (
                    <Button
                        className={classes.addFeatureButton}
                        onClick={() => {
                            this.setState({ openModalState: OpenModalState.FEATURE_LIST });
                        }}>
                        <img className={classes.featureButtonIcon} src="/images/events/icon_add_feature.svg" alt="" />
                        {t("events.add")}
                    </Button>
                )}
                {this.renderModal()}
            </div>
        );
    }
}

export default withStyles(style)(
    withTranslation()(withSnackbar(Feature))
);
