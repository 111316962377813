import {EventType, FeatureType} from "../store/events";

export const getEventTypeName = (eventType: EventType): string => {
    switch (eventType) {
        case EventType.GATE_IN:
            return "Gate in";
        case EventType.GATE_OUT:
            return "Gate out";
        case EventType.SEALING:
            return "Sealing";
        case EventType.INSPECTION:
            return "Inspection";
        case EventType.STUFFING:
            return "Stuffing";
        case EventType.WEIGHTING:
            return "Weighting";
        case EventType.UNSEALING:
            return "Unsealing";
        case EventType.OTHER:
            return "Custom event";
    }
}

export const getFeatureTypeName = (featureType: FeatureType) => {
    switch (featureType) {
        case FeatureType.TRUCK_NUMBER:
            return "Truck number";
        case FeatureType.CHASSIS_NUMBER:
            return "Chassis number";
        case FeatureType.LOCATION:
            return "Location";
        case FeatureType.BOOKING_NUMBER:
            return "N° booking";
        case FeatureType.STUFFING_REFERENCE:
            return "Stuffing reference";
        case FeatureType.SIGNATURE:
            return "Signature";
        case FeatureType.TEXT_FIELD:
            return "Text field";
        case FeatureType.CHECK_LIST:
            return "Checklist";
        case FeatureType.SEAL_NUMBER:
            return "Seal number";
        case FeatureType.BOOLEAN:
            return "Boolean";
        case FeatureType.IMAGE:
            return "Image";
    }
}

export const getFeatureIcon = (featureType: FeatureType) => {
    switch (featureType) {
        case FeatureType.TRUCK_NUMBER:
            return "/images/events/icon_number_color.svg";
        case FeatureType.CHASSIS_NUMBER:
            return "/images/events/icon_number_color.svg";
        case FeatureType.LOCATION:
            return "/images/events/icon_location_color.svg";
        case FeatureType.BOOKING_NUMBER:
            return "/images/events/icon_number_color.svg";
        case FeatureType.STUFFING_REFERENCE:
            return "/images/events/icon_number_color.svg";
        case FeatureType.SIGNATURE:
            return "/images/events/icon_signature_color.svg";
        case FeatureType.TEXT_FIELD:
            return "/images/events/icon_textfield_color.svg";
        case FeatureType.CHECK_LIST:
            return "/images/events/icon_checklist_color.svg";
        case FeatureType.SEAL_NUMBER:
            return "/images/events/icon_number_color.svg";
        case FeatureType.BOOLEAN:
            return "/images/events/icon_boolean_color.svg";
        case FeatureType.IMAGE:
            return "/images/events/icon_image_color.svg";
    }
}

export const getEventTypeStyle = (eventType: EventType) => {
    switch (eventType) {
        case EventType.GATE_IN:
            return { backgroundColor: 'rgba(157, 123, 218, 0.1)', color: '#9D7BDA' };
        case EventType.GATE_OUT:
            return { backgroundColor: 'rgba(114, 121, 253, 0.1)', color: '#7279FD' };
        case EventType.SEALING:
            return { backgroundColor: 'rgba(96, 196, 151, 0.1)', color: '#60B690' };
        case EventType.WEIGHTING:
            return { backgroundColor: 'rgba(48, 59, 136, 0.1)', color: '#303B88' };
        case EventType.UNSEALING:
            return { backgroundColor: 'rgba(28, 145, 149, 0.1)', color: '#1C9195' };
        case EventType.OTHER:
        case EventType.INSPECTION:
        case EventType.STUFFING:
            return {backgroundColor: 'rgba(106, 194, 219, 0.1)', color: '#74A5CA'};
    }
}

