import React from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    IconButton,
    Modal,
    Typography,
    withStyles,
    WithStyles
} from "@material-ui/core";
import {withTranslation, WithTranslation} from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";

import {ModuleType, moduleTypes, sessionService} from "../../store/session";
import {getModuleImage, getModuleName, getModuleWeight} from "../../utils/modules.utils";

import ModuleOrganization from "./ModuleOrganization";
import {ClassicButton} from "../Buttons";

import style from "./styles/manageModules";

interface ManageModulesProps extends WithStyles<typeof style>, WithTranslation {
    handleClose: () => void;
    modulesOrganization: ModuleType[];
}

interface ManageModulesState {
    modulesOrganization: ModuleType[];
}

class ManageModules extends React.Component<ManageModulesProps, ManageModulesState> {
    constructor(props: ManageModulesProps) {
        super(props);

        this.state = {
            modulesOrganization: [ ...props.modulesOrganization ],
        };
    }

    handleModuleClick = (moduleType: ModuleType) => {
        let { modulesOrganization } = this.state;

        const index = modulesOrganization.indexOf(moduleType);
        if (index !== -1) {
            modulesOrganization = modulesOrganization.filter((m, i) => index !== i);
        } else {
            modulesOrganization.push(moduleType);
        }

        this.setState({ modulesOrganization });
    }

    renderModule = (moduleType: ModuleType) => {
        const { classes, t } = this.props;

        const { modulesOrganization } = this.state;

        const isSelected = modulesOrganization.includes(moduleType);

        const moduleWeight = getModuleWeight(moduleType);

        return (
            <Card
                key={moduleType}
                variant="outlined"
                onClick={() => {
                    this.handleModuleClick(moduleType);
                }}
                className={isSelected ? classes.moduleCardSelected : classes.moduleCard}
                // 260 is the size of one component | 22 is the size of the marginRight (19) + 3 borders of size 1
                style={{ width: moduleWeight * 260 + 22 * (moduleWeight - 1) }}>
                {isSelected && (
                    <Box className={classes.moduleSelected}>
                        <IconButton className={classes.selectedButton}>
                            <img src="/images/dashboard/icon_dashboard_move.svg" alt="" />
                        </IconButton>
                        <img
                            className={classes.selectedButton}
                            src="/images/dashboard/icon_dashboard_check.svg"
                            alt="" />
                    </Box>
                )}
                <CardMedia
                    className={classes.moduleImage}
                    image={getModuleImage(moduleType)} />
                <CardContent className={classes.moduleContent}>
                    <Typography className={classes.moduleTitle}>
                        {t(getModuleName(moduleType))}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    render() {
        const { classes, handleClose, t } = this.props;

        const { modulesOrganization } = this.state;

        const modules = modulesOrganization
            .concat(moduleTypes
                .filter((type) => !modulesOrganization.includes(type))
            );

        return (
            <Modal open onClose={handleClose}>
                <Card className={classes.paper}>
                    <CardHeader
                        className={classes.header}
                        titleTypographyProps={{ classes: { root: classes.title } }}
                        action={(
                            <IconButton onClick={handleClose} className={classes.close}>
                                <CloseIcon />
                            </IconButton>
                        )}
                        title={t("permissions.eventAttribution")} />
                    <CardContent className={classes.content}>
                        <ModuleOrganization
                            modules={
                                modules.map((type, index) => ({
                                    type,
                                    position: index,
                                    isSelected: modulesOrganization.includes(type),
                                    weight: getModuleWeight(type),
                                    render: () => this.renderModule(type),
                                }))
                            }
                            updateOrder={(modules) => {
                                this.setState({
                                    modulesOrganization: modules.filter(
                                        (type) => modulesOrganization.includes(type)
                                    ),
                                });
                            }} />
                    </CardContent>
                    <div className={classes.save}>
                        <ClassicButton
                            onClick={() =>  {
                                sessionService.saveModulesOrganization(modulesOrganization);
                                handleClose();
                            }}
                            title={t("dashboard.save")} />
                    </div>
                </Card>
            </Modal>
        );
    }
}

export default withStyles(style)(
    withTranslation()(ManageModules)
);
