import {createStyles} from "@material-ui/core";

const launchingScreen = () => createStyles({
    launchingScreen: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        justifyContent: 'center',
        margin: 'auto',
        maxWidth: 550,
    },
    logo: {
        alignSelf: 'center',
        marginBottom: 80,
        width: 350,
    },
    loader: {
        color: '#3AB4FB',
        margin: '70px auto 0',
    },
});

export default launchingScreen;
