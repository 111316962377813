import {createStyles} from "@material-ui/core";

const containerDetails = () => createStyles({
    container: {
        display: 'flex',
        flex: 1,
    },
    gridContent: {
        display: 'flex',
        flex: 1,
        padding: '40px 50px',
    },
    title: {
        alignItems: 'center',
        color: '#1E2F4F',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 26,
        fontWeight: 700,
        justifyContent: 'center',
        padding: '40px 0 20px',
        "& > :last-child": {
            position: 'absolute',
            right: 20,
            top: 20,
        },
    },
    details: {
        alignItems: 'center',
        color: '#1E2F4F',
        display: 'flex',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 18,
        fontWeight: 700,
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    paper: {
        backgroundColor: '#F3F5F9',
        borderRadius: 5,
        marginBottom: 15,
        padding: 10,
        "& > :nth-child(n):not(:last-child)": {
            marginBottom: 10,
        },
    },
    detailTitle: {
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 16,
        fontWeight: 500,
    },
    detailValue: {
        color: '#7E889A',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
    },
    exportDataButton: {
        float: 'right',
        height: 50,
        width: 50,
    },
    cardContainer: {
        display: 'flex',
        height: 'calc(100% - 40px - 40px)',
    },
});

export default containerDetails;
