import {createStyles, fade, Theme} from "@material-ui/core";

const buttons = (theme: Theme) => createStyles({
    classicButton: {
        backgroundColor: '#43A5FF',
        borderRadius: 10,
        color: 'white',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
        minWidth: 100,
        padding: '5px 25px',
        textTransform: 'none',
    },
    defaultButton: {
        backgroundColor: '#E7EAF3',
        borderRadius: 10,
        color: '#1E2F4F',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 700,
        height: '100%',
        paddingLeft: 16,
        paddingRight: 8,
        textTransform: 'none',
    },
    defaultIcon: {
      marginLeft: 27,
    },
    customCheckboxControl: {
        width: '100%',
    },
    customCheckboxContainer: {
        '&$customCheckboxChecked': {
            color: 'rgb(30, 47, 79)',
            '&:hover': {
                backgroundColor: fade('rgb(30, 47, 79)', theme.palette.action.hoverOpacity),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        '&:hover': {
            backgroundColor: fade('rgb(30, 47, 79)', theme.palette.action.hoverOpacity),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        margin: '0px 2.5px',
        padding: 5,
    },
    customCheckboxChecked: {},
    customCheckboxLabel: {
        color: 'rgb(30, 47, 79)',
        fontFamily: '"Roboto", sans-serif',
        fontSize: 14,
        fontWeight: 400,
    },
});

export default buttons;
