import {User} from "../session";
import {EventType} from "../events";

export interface ContainerDetailsParams {
    containerId: string;
    serialNumber: string;
    cycleId?: string;
}

export interface AutoCompleteResult {
    results: string[];
}

export interface TruckFrame {
    id: string;
    number: string;
    entranceContainerSerialNumber: string;
    enterSiteAt: string;
    releaseContainerSerialNumber: string;
    leaveSiteAt: string;
}

export interface TrucksFramesPaging {
    results: TruckFrame[];
    totalItemCount: number;
}

export interface TurnTime {
    containerId: string;
    serialNumber: string;
    cycleId: string;
    minutesElapsed: number;
    operatorFirstName: string;
    operatorLastName: string;
}

export interface TurnTimeEvent {
    eventTemplateId: string;
    eventTemplateName: string;
    eventTemplateType: EventType;
    turnTimes: TurnTime[];
}

export interface LightContainer {
    serialNumber: string;
    sizeClass: string;
    tare?: string;
    typeClass: string;
}

export interface Container {
    containerId: string;
    serialNumber: string;
    cycleId: string;
    arrivalOnSite: string;
    lastEventLabel: string;
    lastEventType: EventType;
    lastEventDate: string;
    latitude: number;
    longitude: number;
    operatorFirstName: string;
    operatorLastName: string;
}

export interface ContainersPaging {
    results: Container[];
    totalItemCount: number;
}

export interface StatsStillOnSite {
    sealed: number;
    unsealed: number;
    total: number;
}

export interface EventCount {
    count: number;
    eventTemplateId: string;
    eventTemplateName: string;
    eventTemplateType: EventType;
}

export interface TimeLapseOnSite {
    days: number;
    sealedCount: number;
    unsealedCount: number;
}

export interface StatsLastLocation {
    id: string;
    serialNumber: string;
    cycleId: string;
    lastEventType: EventType;
    latitude: number;
    longitude: number;
}

export interface TruckFrameTimeLapseOnSite {
    hours: number;
    count: number;
}

export interface SiteProcessStep {
    id: string;
    label: string;
    type: EventType;
    index: number;
}

export enum Role {
    SITE_OWNER = "SITE_OWNER",
    ADMIN = "ADMIN",
    VIEWER = "VIEWER"
}

export const roles = [ Role.ADMIN, Role.VIEWER ];

export interface Location {
    latitude: number;
    longitude: number;
}

export interface UserSite {
    id: string;
    name: string;
    role: Role;
    location: Location;
}

export interface SitePermission {
    role: Role,
    user: User,
}

export enum SearchType {
    CONTAINER = "container",
    TRUCK = "truck",
    CHASSIS = "chassis",
}

export interface Filters {
    startDate: Date;
    endDate: Date;
    searchType: SearchType;
    search: string;
}

export interface Site {
    permissions: SitePermission[];
    filters: Filters;
}
